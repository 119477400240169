.card {
    border-radius: 10px;
    padding: 3% 4%;
    flex: 1;
    z-index: 20;
}

@media only screen and (max-width: 768px) {
    .card {
        padding: 30px;
    }
}