.dataSources {
    display: grid;
    grid-template-columns: repeat(5, minmax(100px, 1fr));
    grid-template-rows: repeat(3, minmax(100px, 1fr));
    gap: 100px;
}

.dataSources .img {
    position: relative;
    width: 100%;
    height: 0;
    overflow: hidden;
    object-fit: contain;
    padding-top: 50%;
}

.dataSources .img img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media only screen and (max-width: 768px) {
    .dataSources {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(5, 1fr);
    }
}