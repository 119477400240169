footer {
    background-color: #1e2140 !important;
    color: #445468!important;
}

.footer_wrap{
    margin: auto;
    padding: 81px 0;
    width: 80%;
    max-width: 1080px;
}

#footer_logo_icons_wrap {
    width: 47%;
    display: inline-block;
    margin-right: 5.5%;
    vertical-align: top;
}

#footer_logo_wrap{
    margin-bottom: 5%;
    width: 40%;
}

#footer_icons_wrap{
    width: 90%;
}

img {
    height: auto;
    max-width: 100%;
}

#footer_nav_wrap {
    margin-right: 5.5%;
}

#footer_nav_wrap ul {
    margin: 0px;
    padding: 0px;
    font-size: 14px;
    font-weight: 500;
    line-height: 23.8px;
}

#footer_nav_wrap,
#footer_privacyFollowUs_wrap{
    width: 20%;
    display: inline-block;
    vertical-align: top;
}

#footer_privacy_wrap p {
    margin-top: 0px;
    margin-bottom: 13%;
    font-weight: 500;
}

#footer_ul_socials {
    margin: 0px;
    padding: 0px;
}

.footer_followUs_wrap h4 {
    font-size: 17px;
    font-weight: 500;
    margin-top: 0%;
    margin-bottom: 0.5vw;
}

.footer_ul_menu,
#footer_ul_socials {
    list-style: none;
}

.footer_li_menu{
    margin-bottom: 4%;
}

footer a {
    color: #fff;
    font-size: 17px;
}

#footerLtd_wrap {
    border-top: 1px solid #333;
    margin: auto;
    width: 100%;
    max-width: 1080px;
    padding: 27px 0px;
}

#footerLtd_wrap p {
    margin: 0px;
    font-size: 17px;
    font-weight: 500;
    line-height: 23.8px;
}

.active {
    color: #33d1ad;
}

.inactive {
    color: white;
}

@media only screen and (max-width: 980px) {
    .footer_wrap,
    #footerLtd_wrap {
        width: 70%;
        margin: auto;
    }
}

@media only screen and (max-width: 768px) {
    .footer_wrap,
    #footerLtd_wrap {
        width: 90%;
        margin: auto;
    }
}