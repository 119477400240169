.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    max-width: 80%;
    background-color: #1e2140;
    border-radius: 3px;
    padding: 20px;
    box-shadow: 5px 5px 20px rgb(0 0 0 / 50%);
}

.modal:focus {
    outline: none;
}

.modal h4 {
    color: white;
    font-size: 25px;
    margin-bottom: 30px;
}

.formRow {
    width: 100%;
    display: flex;
    gap: 16px;
    margin-bottom: 40px;
}

.formRow > * {
    flex: 1;
    font-size: 25px;
}

.formRow input, textarea, select {
  padding: 14px 22px;
  font-family: inherit;
  font-weight: 300;
  border-radius: 10px;
  border: none;
  color: #475466;
  box-sizing: border-box;
}

.formRow select {
  border-right: 16px solid transparent;
}

.formRow input::placeholder, textarea::placeholder {
  color: #475466;
}

.formRow *:focus {
  outline: none;
}

.smallBlurb {
    color: white;
}

.errorBox {
    border: 1.5px solid #c02b0a;
    background-color: #fff9f9;
    margin-bottom: 15px;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 1px 4px rgba(0,0,0,.11),0 0 4px rgba(18,25,97,.0405344);
}

.errorBox p {
    margin: 0px;
    color: #c02b0a;
    font-weight: 500;
}

.errorBox li {
    color: #c02b0a;
}

.errorBox strong {
    font-weight: 500;
}

@media only screen and (max-width: 768px) {
    .formRow {
        display: block;
        margin-bottom: 0;
    }

    .formRow > * {
        display: block;
        width: 100%;
        margin-bottom: 20px;
    }
}