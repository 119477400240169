.postSuggestions {
    width: 62%;
    margin: auto;
    padding: 82px 0px;
}

.postSuggestions h2 {
    font-size: 48px;
    font-weight: 500;
    color: #1e2140;
}

.snippets {
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 980px) {
    .postSuggestions {
        width: 80%;
    }

    .snippets {
        flex-wrap: wrap;
    }
}