.container {
    background-image: url("../../../../public/images/backgrounds/Identeq-Background-White.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding-top: 52px;
    padding-bottom: 36px;
    z-index: -10;
    color: #1e2140;
}

.contentWrap {
    width: 62%;
    margin: auto;
}

.headerWrap {
    padding: 27px 0px;
}

.contentWrap h1 {
    font-size: 70px;
    font-weight: 700;
    line-height: 80px;
    margin: 0px;
    padding-bottom: 10px;
}

.contentWrap h3 {
    font-size: 41px;
    font-weight: 700;
    line-height: 40px;
    padding-bottom: 10px;
    margin-top: 0px;
    margin-left: 0px;
    margin-bottom: 8.696%;
    margin-right: 8%;
}

.info {
    display: flex;
    gap: 9%;
}

.info > div {
    flex: 1 1 0;
}

.right div {
    margin-bottom: 8.696%;
}

.largeText {
    font-size: 23px;
    font-weight: 500;
    line-height: 36px;
}

.smallText {
    font-size: 17px;
    font-weight: 500;
    line-height: 27px;
}

.buttonWrap a {
    border-color: #1e2140;
    background-color: #1e2140;
    color: #FFF;
    cursor: pointer;
    border-radius: 50px;
    border-width: 0;
    font-size: 18px;
    font-weight: 700 !important;
    line-height: 1.7em !important;
    padding: 15px 30px;
}

.buttonWrap a::after {
    content: "\f054";
    font-family: "Font Awesome 5 Pro";
    font-weight: 500;
    margin-left: -0.5rem;
    opacity: 0;
    transition: all 300ms ease 0ms;
}

.buttonWrap a:hover::after {
    margin-left: 0;
    padding-left: 5px;
    opacity: 1;
}

@media only screen and (max-width: 980px) {
    .contentWrap {
        width: 70%;
    }

    .contentWrap h1 {
        font-size: 50px;
    }

    .contentWrap h3 {
        font-size: 35px;
    }

    .info {
        display: block;
    }

    .imgContainer {
        margin-bottom: 5%;
    }
}

@media only screen and (max-width: 768px) {
    .contentWrap {
        width: 90%;
    }
}