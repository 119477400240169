.animate__fadeTop {
    animation: fadeTop 1s cubic-bezier(.77, 0, .175, 1) 1;
}

@keyframes fadeTop {
    0% {
        opacity: 0;
        transform: translateY(-10%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.modules p {
    font-size: 22px;
    font-weight: 500;
    line-height: 36px;
    color: #1e2140;
}

.twoTypes {
    padding-bottom: 27px;
}

.serviceTypes {
    display: flex;
    justify-content: space-between;
    gap: 3%;
}

.modules .serviceTypes p {
    font-size: 22px;
    font-weight: 500;
    line-height: 36px;
    width: 48.5%;
}

.boxesWrap {
    display: flex;
    justify-content: space-between;
    gap: 3%;
    padding: 27px 0px;
}

.moduleBox {
    transition: transform 300ms ease 0ms;
  
    display: inline;
    line-height: 27px;
    padding-left: 1.5vw;
    padding-top: 1.5vw;
    padding-right: 1.5vw;
    padding-bottom: 1.5vw;
    width: 22.75%;
}

.moduleBox#cotGreen {
    background: linear-gradient(to right, #33D1AD 6px, transparent 6px) 0 0, linear-gradient(to right, #33D1AD 6px, transparent 6px) 0 100%, linear-gradient(to left, #33D1AD 6px, transparent 6px) 100% 0, linear-gradient(to left, #33D1AD 6px, transparent 6px) 100% 100%, linear-gradient(to bottom, #33D1AD 6px, transparent 6px) 0 0, linear-gradient(to bottom, #33D1AD 6px, transparent 6px) 100% 0, linear-gradient(to top, #33D1AD 6px, transparent 6px) 0 100%, linear-gradient(to top, #33D1AD 6px, transparent 6px) 100% 100%;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-color: white;
    box-sizing: border-box;
}

.moduleBox#cotBlack {
    background: linear-gradient(to right, #000 6px, transparent 6px) 0 0, linear-gradient(to right, #000 6px, transparent 6px) 0 100%, linear-gradient(to left, #000 6px, transparent 6px) 100% 0, linear-gradient(to left, #000 6px, transparent 6px) 100% 100%, linear-gradient(to bottom, #000 6px, transparent 6px) 0 0, linear-gradient(to bottom, #000 6px, transparent 6px) 100% 0, linear-gradient(to top, #000 6px, transparent 6px) 0 100%, linear-gradient(to top, #000 6px, transparent 6px) 100% 100%;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-color: white;
    box-sizing: border-box;
}

.moduleBox p {
    font-size: 17px;
    font-weight: 500;
    line-height: 27px;
    margin: 0px;
}

.boxImgWrap {
    margin-bottom: 30px;
}

.boxImgWrap img {
    display: block;
}

.boxTextWrap a {
    color: black;
    margin: -1.5vw;
    display: inline-block;
    z-index: 1;
    padding: 1.5vw;
}

.boxTextWrap h4 {
    color: black;
    margin: 0px;
    padding-bottom: 10px;
    font-size: 22px;
    font-weight: 700;
    line-height: 36px;
}
