#contact_wrap {
    display: flex;
    width: 62%;
    margin: auto;
    padding: 15px 0px;
}

.right,
.left {
    width: 50%;
}

.contact_inner h2 {
    font-weight: 700;
    font-size: 50px;
    line-height: 1em;
    color: #1e2140 !important;
    margin: 0px;
    padding-bottom: 10px;
}

.contact_inner p {
    font-size: 22px;
    font-weight: 500;
    line-height: 36px;
    width: 70%;
    max-width: 75%;
    margin: 0px;
    padding: 1vw 0;
}

#get_in_touch {
    padding-top: 0px;
    padding-bottom: 1.2vw
}

.contact_wrap {
    display: table;
}

.contact_inner {
    display: table-cell;
}

.form_Contact_wrap{
    display: inline-block;
    margin: 2% 0%;
    width: 100%;
}

#form_Contact {
    margin: 24px 0px;
}

.form_Contact_wrap input,
.form_Contact_wrap select,
.form_Contact_wrap textarea {
    font-family: tenon, sans-serif;
    font-size: 14px !important;
    color: #1e2140;
    background-color: #f7f7f7 !important;
    border: 1px solid #f7f7f7 !important;
    border-radius: 8px !important;
    box-sizing: border-box;
    padding: 6px 10px;
    height: 38px;
    width: 100%;
    line-height: 1.3;
    outline: none;
}

.form_Contact_wrap input::placeholder,
.form_Contact_wrap textarea::placeholder,
.form_Contact_wrap select::placeholder {
    font-family: tenon, sans-serif;
    color: #A9A9AC !important;
}

#form_Contact_submit {
    padding: 10px 15px;
}

select,
select option {
  color: #1e2140;
}

select:invalid,
select option[disabled] {
  color: #A9A9AC;
}

[hidden] {
  display: none;
}

#message {
    height: 220px;
}

#FullName_wrap,
#Company_wrap,
#PhoneNumber_wrap,
#Email_wrap {
    width: 48%;
}

#Company_wrap,
#Email_wrap {
    margin-left: 4%;
}

.contactImg {
    color: #33d1ad;
    width: 28px;
    height: auto;
    margin-right: 20px;
    animation: fadeTop 1s cubic-bezier(.77,0,.175,1) 1;
}

.contact_wrap {
    line-height: 1em;
    margin-bottom: 20px;
}

.contact_wrap a {
    color: #000;
}

.contact_wrap h4 {
    font-size: 26px;
    font-weight: 400;
    margin: 2% 0;
}

@keyframes fadeTop {
    0% {
        opacity: 0;
        transform: translateY(-10%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

#contact_form_privacy p {
    padding: 0px;
    line-height: 1.7em;
    font-size: 13px;
    font-weight: 500;
}

#contact_form_privacy a {
    color: #1e2140;
    text-decoration: underline;
}

.errorBox {
    border: 1.5px solid #c02b0a;
    background-color: #fff9f9;
    margin-bottom: 15px;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 1px 4px rgba(0,0,0,.11),0 0 4px rgba(18,25,97,.0405344);
}

.errorBox p {
    margin: 0px;
    color: #c02b0a;
    font-weight: 500;
}

.errorBox li {
    color: #c02b0a;
}

.errorBox strong {
    font-weight: 500;
}

@media only screen and (max-width: 980px) {
    #contact_wrap {
        display: block;
        width: 70%;
    }

    #contact_wrap > * {
        width: 100%;
    }

    #contact_wrap h4 {
        font-size: 18px;
    }

    .contact_inner p {
        width: 100%;
        max-width: none;
    }
}

@media only screen and (max-width: 768px) {
    #contact_wrap {
        width: 90%;
    }
}