#careers_joinOurTeam {
    padding-top: 110px;
    padding-bottom: 105px;
    color:#1e2140;
}

#careers_directors_wrap {
    display: flex;
    flex-wrap: wrap;
    background-color: #F7F7F7;
    color:#1e2140;
}

#careers_directors_wrap .content-wrap {
    width: 62%;
    margin: auto;
    padding-top: 49px;
    padding-bottom: 54px;
}

.careers_header_wrap {
    color: #1e2140 !important;
    font-weight: 700;
    padding: 27px 0px;
}

.careers_header_wrap h1 {
    font-size: 110px;
    margin: 0;
    padding-bottom: 10px;
    line-height: 1em;
}

.careers_header_wrap h2 {
    margin: 0px;
    padding-bottom: 10px;
    font-size: 50px;
    line-height: 1em;
}

.careers_text_wrap {
    width: 62%;
    margin: auto;
}

.careers_text_wrap #would-you-like {
    font-size: 22px;
    font-weight: 500;
    line-height: 36px;
    width: 68%;
    margin: auto;
    padding-bottom: 27px;
}

.careers_text_wrap #if-you-possess {
    font-size: 17px;
    font-weight: 500;
    line-height: 27px;
    width: 59.5%;
    margin: auto;
    padding-bottom: 42px;
}

#careers_joinOurTeam {
    background-color: #33d1ad !important;
    text-align: center;
}

.careers_director_wrap {
    display: inline-block;
    width: 48.5%;
}

.directors {
    width: 100%;
    padding-bottom: 27px;
}

#ben_wrap{
    margin-right: 3%;
}

.director-info h4 {
    margin: 0px;
    padding-bottom: 10px;
    font-size: 28px;
    line-height: 1em;
    font-weight: 700;
}

.director-info p {
    margin: 0px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.7em;
}

.careers_director_img_wrap {
    padding: 4px;
    background: linear-gradient(to right, #33D1AD 6px, transparent 6px) 0 0, linear-gradient(to right, #33D1AD 6px, transparent 6px) 0 100%, linear-gradient(to left, #33D1AD 6px, transparent 6px) 100% 0, linear-gradient(to left, #33D1AD 6px, transparent 6px) 100% 100%, linear-gradient(to bottom, #33D1AD 6px, transparent 6px) 0 0, linear-gradient(to bottom, #33D1AD 6px, transparent 6px) 100% 0, linear-gradient(to top, #33D1AD 6px, transparent 6px) 0 100%, linear-gradient(to top, #33D1AD 6px, transparent 6px) 100% 100%;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    text-align: center;
    margin-bottom: 3.1%;
}

.careers_director_img_wrap img {
    width: 100%;
    height: auto;
    display: block;
}

#careers_workingAtIDenteq {
    width: 62%;
    margin: auto;
    padding-top: 49px;
    padding-bottom: 54px;
}

.careers_workingAtIDenteq_row{
    display: flex;
    justify-content: space-between;
    gap: 3%;
}

.careers_workingAtIDenteq_row#row-2 {
    padding: 27px 0px;
}

.careers_workingAtIDenteq_box{
    background-color: #445468;
    color: #fff !important;
    border-radius: 8px;

    overflow: hidden;
    padding: 2vw 2.5vw;
    width: 48%;
}

.careers_workingAtIDenteq_box p {
    margin: 0px;
    font-size: 17px;
    font-weight: 500;
    line-height: 27px;
}

.careers_workingAtIDenteq_box h4 {
    margin: 0px;
    padding-bottom: 10px;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.3em;
}

.contact-wrap {
    padding-top: 90px;
    padding-bottom: 130px;
}

@media only screen and (max-width: 980px) {
    .careers_header_wrap,
    .careers_text_wrap,
    #careers_directors_wrap .content-wrap,
    #careers_workingAtIDenteq {
        width: 70%;
    }

    #careers_joinOurTeam .careers_header_wrap {
        margin: auto;
    }

    .careers_header_wrap h1 {
        font-size: 70px;
    }

    .careers_header_wrap h2 {
        font-size: 30px;
    }

    .careers_text_wrap #would-you-like,
    .careers_text_wrap #if-you-possess {
        margin: 0;
        width: 100%;
    }

    .careers_director_wrap {
        display: block;
        width: 100%;
    }

    #ben_wrap {
        margin-right: 0;
        margin-bottom: 50px;
    }

    .careers_workingAtIDenteq_row {
        display: block;
    }

    .careers_workingAtIDenteq_box {
        width: 100%;
        margin-bottom: 27px;
        box-sizing: border-box;
    }

    .careers_workingAtIDenteq_row#row-2 {
        padding: 0;
    }
}

@media only screen and (max-width: 768px) {
    .careers_header_wrap,
    .careers_text_wrap,
    #careers_directors_wrap .content-wrap,
    #careers_workingAtIDenteq {
        width: 90%;
    }
    
    .careers_workingAtIDenteq_box {
        padding: 4vw;
    }

}