.container {
    padding-top: 103px;
    padding-bottom: 54px;
    background-color: #f7f7f7;
    color: #1e2140;
}

.contentWrap {
    width: 62%;
    margin: auto;
}

.headerWrap {
    padding: 27px 0px;
}

.headerWrap h2 {
    margin: 0px;
    padding-bottom: 10px;
    font-size: 35px;
    font-weight: 700;
    line-height: 40px;
}

.boxWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.box {
    display: inline-block;
    width: 31.333%;
    background: linear-gradient(to right, #33D1AD 6px, transparent 6px) 0 0, linear-gradient(to right, #33D1AD 6px, transparent 6px) 0 100%, linear-gradient(to left, #33D1AD 6px, transparent 6px) 100% 0, linear-gradient(to left, #33D1AD 6px, transparent 6px) 100% 100%, linear-gradient(to bottom, #33D1AD 6px, transparent 6px) 0 0, linear-gradient(to bottom, #33D1AD 6px, transparent 6px) 100% 0, linear-gradient(to top, #33D1AD 6px, transparent 6px) 0 100%, linear-gradient(to top, #33D1AD 6px, transparent 6px) 100% 100%;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-color: white;
    margin: 27px 0px;
    padding: 38px;
    box-sizing: border-box;
    color: #333;
}

.iconContainer {
    margin-bottom: 30px;
}

.box h4 {
    font-size: 22px;
    font-weight: 700;
    line-height: 36px;
    margin: 0px;
    padding-bottom: 10px;
}

.box p {
    margin: 0px;
    font-size: 17px;
    font-weight: 400;
    line-height: 27px;
}

@media only screen and (max-width: 980px) {
    .contentWrap {
        width: 70%;
    }

    .boxWrapper {
        display: block;
    }

    .box {
        display: block;
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .contentWrap {
        width: 90%;
    }
}