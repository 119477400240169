/* #technology_ModernTechnology_wrap, */
/* #technology_SaaS_wrap, */
/* #technology_OurStack_wrap { */
/*   padding: 2% 17.5%; */
/* } */

.technology-content-wrap {
    width: 62%;
    margin: auto;
    color: #1e2140;
}

#technology_SaaS_wrap {
    background-color: #f7f7f7;
    padding-top: 49px;
    padding-bottom: 54px;
}

#technology_ModernTechnology_wrap {
    padding: 90px 0px;
}

#technology_OurStack_wrap {
    padding-top: 49px;
    padding-bottom: 54px;
}

.technology-header-wrap {
    padding: 27px 0px;
}

#technology_ModernTechnology_wrap h1 {
    font-size: 70px;
    font-weight: 700;
    line-height: 1em;
    margin: 0px;
    padding-bottom: 10px;
}

#technology_ModernTechnology_wrap p {
    font-size: 36px;
    font-weight: 500;
    line-height: 40px;
    margin: 0px;
    padding: 27px 0px;
}

#technology_SaaS_blurb {
    margin: 0px;
    font-size: 22px;
    font-weight: 500;
    line-height: 36px;
    width: 66%;
}

.technology_SaaS_box p {
    font-size: 17px !important;
}

#technology_SaaS_wrap h2 {
    font-weight: 700;
    font-size: 50px;
    margin: 0px;
    padding-bottom: 10px;
}

#technology_OurStack_wrap h2 {
    font-weight: 700;
    font-size: 35px;
    margin: 0px;
    padding-bottom: 10px;
}

#technology_SaaS_box_wrap {
    display: flex;
    flex-wrap: wrap;
    padding: 27px 0px;
    justify-content: space-between;
    column-gap: 3%;
    row-gap: 54px;
}

.technology_SaaS_box {
    transition: transform 300ms ease 0ms;
    background: linear-gradient(to right, #33D1AD 6px, transparent 6px) 0 0, linear-gradient(to right, #33D1AD 6px, transparent 6px) 0 100%, linear-gradient(to left, #33D1AD 6px, transparent 6px) 100% 0, linear-gradient(to left, #33D1AD 6px, transparent 6px) 100% 100%, linear-gradient(to bottom, #33D1AD 6px, transparent 6px) 0 0, linear-gradient(to bottom, #33D1AD 6px, transparent 6px) 100% 0, linear-gradient(to top, #33D1AD 6px, transparent 6px) 0 100%, linear-gradient(to top, #33D1AD 6px, transparent 6px) 100% 100%;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-color: white;

    display: inline;
    width: 31.3333%;
    box-sizing: border-box;
    padding-top: 2vw;
    padding-bottom: 2vw;
    padding-left: 2vw;
    padding-right: 1vw;
}

.technology_SaaS_box_image_wrap {
    margin-bottom: 30px;
}

.technology_SaaS_box h4 {
    margin: 0px;
    padding-bottom: 10px;
    font-size: 22px;
    font-weight: 700;
    line-height: 36px;
}

.technology_SaaS_box p {
    margin: 0px;
    font-size: 17px;
    font-weight: 500;
    line-height: 27px;
}

.technology_OurStack_row {
    display: flex;
    padding: 27px 0px;
}

.technology_OurStack_divider {
    border: 2px solid #33d1ad;
    margin-bottom: 23px;
}

.technology_OurStack_column {
    width: 33%;
}

.technology_OurStack_column h3 {
    margin: 0px;
    padding-bottom: 10px;
    font-size: 20px;
    font-weight: 700px;
    line-height: 36px;
}

.technology_OurStack_inner_wrap {
    display: flex;
    margin-bottom: 20px;
}

.technology_OurStack_inner_wrap.ourstack-last-row {
    margin: 0px;
}

.technology_OurStack_inner_wrap div {
    display: inline-table;
    /* margin: 2% 1%; */
}

.ourstack-img-wrap {
    width: 32px;
}

.technology_OurStack_inner_wrap h4 {
    display: table-cell;
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    margin: 0;
    padding-bottom: 10px;
    padding-left: 15px;
    vertical-align: middle;
}

.technology_OurStack_inner_wrap img {
    width: 100%;
    height: auto;
}

.contact-wrap {
    padding-top: 90px;
    padding-bottom: 130px;
}

.animate__fadeTop {
  animation: fadeTop 1s cubic-bezier(.77, 0, .175, 1) 1;
}

@keyframes fadeTop {
    0% {
        opacity: 0;
        transform: translateY(-10%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media only screen and (max-width: 980px) {
    .technology-content-wrap {
        width: 70%;
    }

    #technology_ModernTechnology_wrap h1 {
        font-size: 50px;
    }

    #technology_SaaS_wrap h2 {
        font-size: 30px;
    }
    
    #technology_SaaS_blurb {
        width: 100%;
    }

    #technology_ModernTechnology_wrap p,
    #technology_SaaS_wrap p {
        font-size: 20px;
    }

    #technology_SaaS_box_wrap {
        display: block;
    }

    .technology_SaaS_box {
        display: block;
        width: 100%;
        padding: 4vw;
        margin-bottom: 30px;
    }

    .technology_OurStack_row {
        display: block;
    }

    .technology_OurStack_column {
        width: 100%;
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 768px) {
    .technology-content-wrap {
        width: 90%;
    }
}