.contactWrap {
    padding: 90px 0px;
}

.headerWrap {
    padding-bottom: 27px;
    width: 62%;
    margin: auto;
}

.headerWrap h1 {
    font-size: 70px;
    font-weight: 700;
    line-height: 80px; 
    margin: 0px;
    padding-bottom: 10px;
    color: #1e2140;
}

@media only screen and (max-width: 980px) {
    .headerWrap {
        width: 70%;
    }

    .headerWrap h1 {
        font-size: 50px;
    }
}

@media only screen and (max-width: 768px) {
    .headerWrap {
        width: 90%;
    }
}