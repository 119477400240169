.dataSources {
    background-color: #F7F7F7;
}

.contentWrap {
    width: 62%;
    margin: auto;
    color: #1e2140;
}

.headerWrap {
    padding: 27px 0px;
}

.headerWrap h2 {
    font-size: 38px;
    font-weight: 700;
    line-height: 40px;
    color: #1e2140;
    margin: 0px;
    padding-bottom: 10px;
}

.textWrap {
    width: 70%;
}

.textWrap p {
    font-size: 22px;
    font-weight: 400;
    line-height: 36px;
    margin: 0px;
}

.logosRow {
    display: flex;
    justify-content: center;
    gap: 3%;
    padding: 27px 0px;
}

.logosRow#lastRow {
    padding-top: 0px;
    padding-bottom: 27px;
}

@media only screen and (max-width: 980px) {
    .contentWrap {
        width: 70%;
    }

    .textWrap {
        width: 100%;
    }

    .logosRow {
        flex-wrap: wrap;
    }

    .logoWrap {
        width: 40%;
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 768px) {
    .contentWrap {
        width: 90%;
    }
}