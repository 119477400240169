#pmaas_services_wrap {
    background-color: #f7f7f7 !important;
}

#pmaas_bookademo_wrap,
#pmaas_deliverymodel_wrap {
    background-image: url('../../img/Identeq-Background-Blue.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    color: #FFF;
}

.pmaas-content-wrap {
    width: 62%;
    margin: auto;
}

.pmaas-content-wrap p {
    margin: 0px;
}

#pmaas_bookademo_wrap .pmaas-content-wrap {
    padding: 90px 0px;
}

#pmaas_capability_wrap .pmaas-content-wrap,
#pmaas_services_wrap .pmaas-content-wrap,
#pmaas_deliverymodel_wrap .pmaas-content-wrap {
    padding-top: 49px;
    padding-bottom: 54px;
}

#pmaas_capability_wrap .pmaas-content-wrap,
#pmaas_services_wrap .pmaas-content-wrap {
    color: #1e2140;
}

#pmaas_bookademo_h1_wrap {
    width: 100%;
    padding: 27px 0px;
}

#pmaas_bookademo_h1_wrap h1 {
    font-size: 70px;
    font-weight: 700;
    line-height: 1em;
    margin: 0px;
    padding-bottom: 10px;
}

#pmaas_bookademo_imgtext_wrap {
    display: flex;
    padding-top: 27px;
}

#pmaas_bookademo_img_wrap {
    width: 46%;
    margin-right: 8%;
}

#pmaas_bookademo_text_wrap {
    width: 46%;
}

#pmaas_bookademo_text_wrap p {
    font-size: 23px;
    font-weight: 500;
    line-height: 36px;
    text-shadow: 0.08em 0.08em 0em rgba(0,0,0,0.4);
}

#pmaas-first-para {
    padding-bottom: 23px;
}

#pmaas_bookademo_text_wrap #pmaas-second-para {
    margin-bottom: 2vw;
}

#pmaas_bookademo_link_wrap {
    margin-top: 30px;
}

#pmaas_capability_box_wrap,
#pmaas_services_box_wrap,
.pmaas-dark-row {
    display: flex;
    padding: 27px 0px;
    justify-content: space-between;
    gap: 3%;
}

.pmaas-dark-middle-row {
    padding: 0px;
}

#pmaas_services_box_wrap {
    margin-bottom: 54px;
}

#pmaas_capability_wrap p,
#pmaas_services_wrap p,
#pmaas_deliverymodel_wrap p {
    font-size: 22px;
    font-weight: 500;
    line-height: 36px;
}

#pmaas_capability_wrap #pmaas-large-p {
    padding: 27px 0px;
    font-size: 36px;
    font-weight: 500;
    line-height: 45px;
}

.pmaas-header-wrap {
    padding: 27px 0px; 
}

#pmaas_deliverymodel_wrap .pmaas-header-wrap {
    padding-top: 0px;
    padding-bottom: 27px;
}

#pmaas_capability_wrap h2,
#pmaas_services_wrap h2,
#pmaas_deliverymodel_wrap h2 {
    margin: 0px;
    padding-bottom: 10px;
    font-size: 50px;
    font-weight: 700;
    line-height: 1em;
}

.pmaas_capability_box {
    border-radius: 8px;
    color: #fff;
    overflow: hidden;
    padding-top: 1.5vw;
    padding-left: 1.5vw;
    padding-bottom: 2vw;
    padding-right: 2vw;
    width: 22.75%;
}

.pmaas_capability_box {
    background-color: #33d1ad;
}

.pmaas_services_box,
.pmaas_services_box_dark {
    display: inline-block;
    width: 22.75%;
}

.pmaas_services_box {
    transition: transform 300ms ease 0ms;
    background: linear-gradient(to right, #33D1AD 6px, transparent 6px) 0 0, linear-gradient(to right, #33D1AD 6px, transparent 6px) 0 100%, linear-gradient(to left, #33D1AD 6px, transparent 6px) 100% 0, linear-gradient(to left, #33D1AD 6px, transparent 6px) 100% 100%, linear-gradient(to bottom, #33D1AD 6px, transparent 6px) 0 0, linear-gradient(to bottom, #33D1AD 6px, transparent 6px) 100% 0, linear-gradient(to top, #33D1AD 6px, transparent 6px) 0 100%, linear-gradient(to top, #33D1AD 6px, transparent 6px) 100% 100%;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-color: white;
    padding: 2vw;
}

.pmaas_services_box h4 {
    line-height: 36px;
}

.pmaas_services_box_image_wrap {
    margin-bottom: 30px;
}

#pmaas-our-exp {
    padding-top: 54px;
}

.pmaas_services_box_dark {
    background-color: #1e2140;
    border-radius: 8px;
    color: #fff;
    overflow: hidden;
    padding: 1.5vw;
    height: 157px;
    box-sizing: border-box;
}

h4 {
    font-size: 22px;
    font-weight: 700;
    line-height: 1.2em;
    margin: 0px;
    padding-bottom: 10px;
}

.contact-wrap {
    padding-top: 90px;
    padding-bottom: 130px;
}

.animate__fadeTop {
  animation: fadeTop 1s cubic-bezier(.77, 0, .175, 1) 1;
}

@keyframes fadeTop {
    0% {
        opacity: 0;
        transform: translateY(-10%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media only screen and (max-width: 980px) {
    .pmaas-content-wrap {
        width: 70%;
    }

    #pmaas_bookademo_h1_wrap h1 {
        font-size: 50px;
    }

    #pmaas_bookademo_imgtext_wrap {
        display: block;
    }

    #pmaas_bookademo_imgtext_wrap > * {
        width: 100%;
    }
    
    #pmaas_bookademo_img_wrap {
        margin-bottom: 30px;
    }

    #pmaas_capability_box_wrap {
        flex-wrap: wrap;
        gap: 20px;
    }

    #pmaas_capability_box_wrap > * {
        width: 43%;
    }

    #pmaas_services_box_wrap {
        display: block;
        padding-bottom: 0;
    }

    #pmaas_services_box_wrap > * {
        display: block;
        width: 100%;
        margin-bottom: 30px;
        padding: 4vw;
        box-sizing: border-box;
    }

    .pmaas_services_box_dark {
        padding: 3.5vw;
    }

    .pmaas-dark-row {
        flex-wrap: wrap;
        gap: 20px;
    }

    .pmaas-dark-row > * {
        width: 47%;
    }
}

@media only screen and (max-width: 768px) {
    .pmaas-content-wrap {
        width: 90%;
    }
}