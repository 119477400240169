.content-wrap {
    width: 62%;
    margin: auto;
}

#cotPortal_bookADemo {
    background-image: url('../../img/Identeq-Background-Green.jpg');
}

#cotPortal_bookADemo .content-wrap {
    padding: 90px 0px;
}

#cotPortal_howItWorks .content-wrap {
    padding-top: 54px;
}

#cotPortal_modules .content-wrap {
    padding-bottom: 54px;
}

#cotPortal_bookDemo_body_wrap {
    display: flex;
    flex-wrap: wrap;
    padding-top: 27px;
}

#cotPortal_bookDemo_img_wrap {
    margin-right: 4%;
    flex: 1;
    /* height: 305px; */
}

.video-container {
    position: relative;
    padding-top: 56.25%;
    height: 0;
}

#cotPortal_bookDemo_img_wrap video {
    display: block;
    object-fit: contain;
    width: 100%;
    height: 100%;
    position:absolute;
    top: 0;
    left: 0;
}

#cotPortal_bookDemo_img_wrap .react-player__preview {
    position:absolute;
    top: 0;
    left: 0;
}


#cotPortal_bookDemo_text_wrap {
    margin-left: 4%;
    flex: 1;
}

#cotPortal_bookDemo_text_wrap p:first-child {
    margin-top: 0;
}

#cotPortal_bookDemo_btn_wrap {
    margin-top: 5%;
}

.cotPortal_box_wrap {
    display: flex;
    justify-content: space-between;
    gap: 3%;
    padding: 27px 0px;
}

.cotPortal_box {
    transition: transform 300ms ease 0ms;
  
    display: inline;
    line-height: 27px;
    padding-left: 2vw;
    padding-top: 2vw;
    padding-right: 2vw;
    padding-bottom: 3.6vw;
    width: 22.75%;
}

.cotPortal_box#cot-green {
    background: linear-gradient(to right, #33D1AD 6px, transparent 6px) 0 0, linear-gradient(to right, #33D1AD 6px, transparent 6px) 0 100%, linear-gradient(to left, #33D1AD 6px, transparent 6px) 100% 0, linear-gradient(to left, #33D1AD 6px, transparent 6px) 100% 100%, linear-gradient(to bottom, #33D1AD 6px, transparent 6px) 0 0, linear-gradient(to bottom, #33D1AD 6px, transparent 6px) 100% 0, linear-gradient(to top, #33D1AD 6px, transparent 6px) 0 100%, linear-gradient(to top, #33D1AD 6px, transparent 6px) 100% 100%;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-color: white;
    box-sizing: border-box;
}

.cotPortal_box#cot-black {
    background: linear-gradient(to right, #000 6px, transparent 6px) 0 0, linear-gradient(to right, #000 6px, transparent 6px) 0 100%, linear-gradient(to left, #000 6px, transparent 6px) 100% 0, linear-gradient(to left, #000 6px, transparent 6px) 100% 100%, linear-gradient(to bottom, #000 6px, transparent 6px) 0 0, linear-gradient(to bottom, #000 6px, transparent 6px) 100% 0, linear-gradient(to top, #000 6px, transparent 6px) 0 100%, linear-gradient(to top, #000 6px, transparent 6px) 100% 100%;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-color: white;
    box-sizing: border-box;
}

.cotPortal_box p {
    font-size: 17px;
    font-weight: 500;
    line-height: 27px;
    margin: 0px;
}

.cotPortal_box_image_wrap {
    margin-bottom: 30px;
}

.cotPortal_box_image_wrap img {
    display: block;
}

#cotPortal_modules > .cotPortal_box_wrap {
    flex-wrap: wrap;
}

#cotPortal_bookADemo .header-wrap {
    padding: 15px 0px;
}

#cotPortal_howItWorks .header-wrap {
    padding-top: 27px;
}

.header-wrap h1 {
    font-size: 70px;
    font-weight: 700;
    line-height: 1em;
    color: #1e2140;
    margin: 0px;
    padding-bottom: 10px;
}

#cotPortal_howItWorks .header-wrap h2 {
    font-size: 48px;
    font-weight: 700;
    line-height: 1em;
    color: #1e2140;
    margin: 0px;
    padding-bottom: 10px;
}

#cotPortal_bookDemo_text_wrap p {
    margin-top: 0px;
    margin-bottom: 19px;
}

#cotPortal_bookDemo_text_wrap .large-text {
    font-size: 23px;
    font-weight: 500;
    line-height: 36px;
    color: #1e2140;
}

#cotPortal_bookDemo_text_wrap .small-text {
    font-size: 17px;
    font-weight: 500;
    line-height: 27px;
    color: #1e2140;
}

#cotPortal_howItWorks,
#cotPortal_multipleDataSources,
#cotPortal_modules {
    background-color: #f7f7f7!important;
}

#cotPortal_multipleDataSources .header-wrap,
#cotPortal_modules .header-wrap {
    padding-top: 27px;
}

#cotPortal_multipleDataSources .header-wrap h2,
#cotPortal_modules .header-wrap h2 {
    font-size: 38px;
    font-weight: 700;
    line-height: 40px;
    color: #1e2140;
    margin: 0px;
    padding-bottom: 10px;
}

#cotPortal_multipleDataSources .cotPortal_text_wrap p {
    margin: 0px;
    font-size: 22px;
    font-weight: 500;
    line-height: 36px;
    color: #1e2140;
    padding-bottom: 27px;
}

.MDS-logos {
    padding: 27px 0px;
}

.cotPortal_multipleDataSources_row {
    display: flex;
    justify-content: center;
    gap: 3%;
    margin-bottom: 1.5%;
}

.MDS-last-row {
    margin-bottom: 0px;
}

.cotPortal_multipleDataSources_row img {
    /* margin-right: 3%; */
    width: 14.1667%;
}

.cotPortal_text_wrap {
    font-size: 22px !important;
    width: 70%;
}

.howitworks-text-wrap {
    padding: 15px 0px;
    width: 70%;
}

.howitworks-text-wrap p {
    font-size: 22px;
    font-weight: 500;
    line-height: 36px;
    margin: 0px;
}

#cotPortal_modules .cotPortal_text_wrap {
    width: 100%;
}

#cotPortal_modules p {
    margin: 0px;
    padding-bottom: 22px;
    line-height: 36px;
    font-weight: 500;
}

#cotPortal_modules .cotPortal_box {
    padding: 1.5vw;
}

.cotPortal_box a {
    color: black;
    margin: -1.5vw;
    display: inline-block;
    z-index: 1;
    padding: 1.5vw;
}

.cotPortal_box h4 {
    margin: 0px;
    padding-bottom: 10px;
    font-size: 22px;
    font-weight: 700;
    line-height: 36px;
}

#cotPortal_text_services_wrap {
    display: flex;
    justify-content: center;
    gap: 3%;
}

.contact-wrap {
    padding-top: 90px;
    padding-bottom: 90px;
}

.animate__fadeTop {
    animation: fadeTop 1s cubic-bezier(.77, 0, .175, 1) 1;
}

@keyframes fadeTop {
    0% {
        opacity: 0;
        transform: translateY(-10%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media only screen and (max-width: 980px) {
    .content-wrap {
        width: 70%;
        margin: auto;
    }

    .header-wrap h1 {
        font-size: 50px;
    }

    .cotPortal_text_wrap,
    .howitworks-text-wrap {
        width: 100%;
    }

    #cotPortal_bookDemo_body_wrap {
        display: block;
    }

    #cotPortal_bookDemo_img_wrap {
        margin-right: 0;
        margin-bottom: 30px;
    }

    #cotPortal_bookDemo_text_wrap {
        margin-left: 0;
    }

    .cotPortal_box_wrap,
    .cotPortal_box {
        display: block;
        width: 100%;
    }

    .cotPortal_box {
        margin-bottom: 30px;
        padding: 4vw;
    }

    .cotPortal_multipleDataSources_row {
        flex-wrap: wrap;
    }

    .cotPortal_multipleDataSources_row img {
        width: 40%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
    }

    #cotPortal_modules .cotPortal_box {
        padding: 4vw;
    }

    #cotPortal_modules .cotPortal_box_wrap {
        padding: 0;
    }
}

@media only screen and (max-width: 768px) {
    .content-wrap {
        width: 90%;
    }
}