header {
  background-color: #1e2140;
  border-top: 4px solid;
  border-color: #33d1ad;
  transition: top 300ms ease, padding 300ms ease;
}

.placeholder_header {
  height: 7vh;
}

header.scroll_up {
  position: relative;
}

header.scroll_down {
  position: fixed;
  padding: 0px;
  z-index: 10010;
  width: 100%;
  left: 0px;
  top: 0;
  bottom: auto;
  margin-top: 0px;
  transition: padding 300ms ease 0ms, width 300ms ease 0ms;
}

.header_wrap {
  display: flex;
  align-items: stretch;
  margin: auto;
  padding: 20px 0px;
  width: 62%;
  transition: padding 300ms ease 0ms, width 300ms ease 0ms;
}

header.scroll_down .header_wrap {
  width: 70%;
  padding: 3px 0px;
}

header.scroll_down #header_logo_wrap a {
  line-height: 100%;
}

#header_logo_wrap,
#header_nav_wrap {
  // margin: auto;
  flex: 1 1 auto;
  transition: padding 300ms ease 0ms, width 300ms ease 0ms;
}

#header_logo_wrap {
  // margin: auto;
  text-align: left;
  flex: 0 1 auto;
  display: flex;
  align-items: center;
}

#header_nav_wrap {
  align-items: stretch;
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  justify-content: flex-end;
  text-align: center;
  width: 100%;
}

#ul_header_menu li {
  text-align: left;
}

header.scroll_down #header_logo_wrap,
header.scroll_down #header_nav_wrap {
  padding: 0%;
  transition: padding 300ms ease 0ms, width 300ms ease 0ms;
}

#img_header_logo {
  height: 100%;
  width: auto;
  max-height: 74px;
  margin-right: 30px;
  transition: max-height 300ms ease 0ms,height 300ms ease 0ms
}

header.scroll_down #img_header_logo {
  height: auto;
  max-height: 60px;
  display: block;
  transition: max-height 300ms ease 0ms,height 300ms ease 0ms
}

#header_nav_wrap {
  align-items: stretch;
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;
}

#nav_header_menu {
  width: 100%;
  display: flex;
  align-items: center;
}

// Nav

#nav_header_menu ul {
  list-style: none;
  width: 100%;
  text-align: right;
  margin: 0px;
}

#nav_header_menu a {
  color: inherit;
}

#nav_header_menu ul li ul {
  position: absolute;
  left: 0;
  top: 100%;
  opacity: 0;
  pointer-events: none;
}

#nav_header_menu ul li:hover > ul {
  align-items: stretch;
  background-color: #1e2140;
  border-top: 3px solid;
  border-color: #33d1ad;
  display: grid;
  // width: 150px;
  width: 240px;
  z-index: 1;
  opacity: 1;
  transition: opacity .2s ease-in-out;
  pointer-events: auto;
  padding-left: 0px;
  padding-top: 20px;
  padding-bottom: 20px;
}

#nav_header_menu li li {
  position: relative;
  line-height: 2em;
  margin: 0;
  padding: 0 20px;
}

#nav_header_menu ul li {
  display: inline-block;
  position: relative;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 6px;
  padding-right: 6px;
  color: white;
}

#nav_header_menu ul li a {
  padding-right: 2px;
}

.menu_icon {
  color: inherit;
}

.menu-item:hover {
  opacity: 70%;
}

.sub-menu a:hover {
  background-color: rgba(0, 0, 0, .03);
}

#nav_header_menu ul ul li {
  background-color: #1e2140;
  box-sizing: border-box;
  // padding-top: 10px;
  // padding-right: 20px;
  // padding-left: 20px;
  // padding-bottom: 10px;
}

#nav_header_menu ul ul li:hover {
  background-color: rgba(0, 0, 0, .03);
}

#nav_header_menu ul ul li,
#nav_header_menu ul ul li a {
  box-sizing: border-box;
  display: block;
  white-space: nowrap;
  padding-right: 20px;
  padding-left: 20px;
  // width: 100%;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 34px;
  transition: width 300ms ease 0ms, height 300ms ease 0ms, top 300ms ease 0ms, right 300ms ease 0ms;
}

header.scroll_down .bm-burger-button {
  position: fixed;
  width: 18px;
  height: 15px;
  right: 18px;
  top: 21px;
  transition: width 300ms ease 0ms, height 300ms ease 0ms, top 300ms ease 0ms, right 300ms ease 0ms;
}

.bm-burger-bars {
  background: #33d1ad;
  border-radius: 5px;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #33d1ad;
}

.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #1e2140;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-item {
  text-align: left;
}

.header_mobile_menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.header_mobile_menu li {
  padding-left: 30px;
  color: white;
}

.header_mobile_menu a {
  color: white;
  padding-top: 15px;
  padding-bottom: 15px;
  display: block;
}

@media only screen and (max-width: 980px) {
  .header_wrap {
    width: 80%;
  }
}

@media only screen and (max-width: 768px) {
  .header_wrap {
    width: 90%;
  }
}