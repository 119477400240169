.contactForm {
    margin: 24px 0px;
}

.inputWrap {
    display: inline-block;
    margin: 2% 0%;
    width: 100%;
}

.inputWrap input,
.inputWrap select,
.inputWrap textarea {
    font-family: tenon, sans-serif;
    font-size: 14px !important;
    color: #1e2140;
    background-color: #f7f7f7 !important;
    border: 1px solid #f7f7f7 !important;
    border-radius: 8px !important;
    box-sizing: border-box;
    padding: 6px 10px;
    height: 38px;
    width: 100%;
    line-height: 1.3;
    outline: none;
}

.inputWrap input::placeholder,
.inputWrap select::placeholder,
.inputWrap textarea::placeholder {
    font-family: tenon, sans-serif;
    color: #A9A9AC !important;
}

#firstNameWrap,
#lastNameWrap {
    width: 48%;
}

#lastNameWrap {
    margin-left: 4%;
}

.inputWrap select,
.inputWrap select option {
  color: #1e2140;
}

.inputWrap select:invalid,
.inputWrap select option[disabled] {
  color: #A9A9AC;
}

.errorBox {
    border: 1.5px solid #c02b0a;
    background-color: #fff9f9;
    margin-bottom: 15px;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 1px 4px rgba(0,0,0,.11),0 0 4px rgba(18,25,97,.0405344);
}

.errorBox p {
    margin: 0px;
    color: #c02b0a;
    font-weight: 500;
}

.errorBox li {
    color: #c02b0a;
}

.errorBox strong {
    font-weight: 500;
}