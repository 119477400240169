.background {
    background-color: #F7F7F7;
    padding-bottom: 54px;
}

.contentWrap {
    width: 62%;
    margin: auto;
    color: #1e2140;
}

.headerWrap {
    padding: 15px 0px;
}

.headerWrap h2 {
    margin: 0px;
    padding-bottom: 10px;
    font-size: 35px;
    font-weight: 700;
    line-height: 40px;
}

.contentWrap p {
    margin: 0px;
    font-size: 22px;
    font-weight: 400;
    line-height: 36px;
}

.blurb {
    width: 70%;
    padding-bottom: 22px;
}

.buttonWrap {
    padding: 27px 0px;
    margin: 15px 0px;
}

.buttonWrap a {
    border-color: #1e2140;
    background-color: #1e2140;
    color: #FFF;
    cursor: pointer;
    border-radius: 50px;
    border-width: 0;
    font-size: 18px;
    font-weight: 700 !important;
    line-height: 1.7em !important;
    padding: 15px 30px;
}

.buttonWrap a::after {
    content: "\f054";
    font-family: "Font Awesome 5 Pro";
    font-weight: 500;
    margin-left: -0.5rem;
    opacity: 0;
    transition: all 300ms ease 0ms;
}

.buttonWrap a:hover::after {
    margin-left: 0;
    padding-left: 5px;
    opacity: 1;
}
