.logoWrap {
    margin-bottom: 30px;
    width: 55%;
}

.right {
    width: 55%;
    min-width: 451px;
    display: flex;
    justify-content: space-between;
}

.footerNav {
    margin-bottom: 60px;
}

.footerNav h4 {
    color: #33d1ad;
    margin-bottom: 5px;
    font-size: 30px;
}

.footer .followUs {
    display: inline;
    margin-left: 10px;
}

.footerNav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.footerNav li {
    line-height: 2em;
}

.footerNav li a {
    color: white;
    font-size: 25px;
}

.footerNav .icon {
    border: 2px solid #33d1ad;
    border-radius: 50%;
    padding: 3px;
    color: #33d1ad;
    vertical-align: middle;
}

.footer .footerDivider {
    margin-top: 30px;
}

.certLogos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    padding: 20px 0;
}

.certLogos .imgWrap {
    width: 45%;
}

.certLogos .imgWrap img {
    object-fit: contain;
    object-position: center;
    width: 100%;
    max-height: 120px;
}

.above a {
    color: #33d1ad;
}
