.navbar {
    margin: 0;
    color: white;
    position: absolute;
    background: transparent;
    z-index: 10;
    width: 100%;
}

.navContent {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 20px 0;
}

.logoWrap {
    flex-grow: 0;
    height: 80px;
    padding: 20px 0;
}

.logoWrap img {
    height: 100%;
    width: auto;
    max-width: none;
}

.navWrap {
    /* display: flex; */
    display: none;
    align-items: center;
    width: 49.5%;
    padding: 50px 0;
}

.navWrap ul {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.navWrap li {
    list-style-type: none;
    font-size: 25px;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
}

.contentWrap {
  width: 75%;
  margin: auto;
  position: relative;
}

@media only screen and (max-width: 980px) {
    .logoWrap img {
        height: 80%;
    }
}

@media only screen and (max-width: 530px) {
    .logoWrap img {
        height: 60%;
    }
}