.contentWrap {
    width: 62%;
    margin: auto;
}

.heroSection .contentWrap {
    padding-top: 110px;
    padding-bottom: 94px;
}

.infoSection .contentWrap {
    padding-top: 50px;
    padding-bottom: 94px;
}

.heroSection {
    background-image: url('../../img/Identeq-Background-Blue.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    color: #FFFFFF;
}

.heroSection .headingWrap {
    padding-bottom: 1.5%;
}

.heroSection h1 {
    font-weight: 700;
    font-size: 64px;
    line-height: 62px;
}

.heroSection .subheadingWrap {
    margin-bottom: 1.5%;
}

.heroSection p {
    font-size: 22px;
}

.contentWrap p {
    font-weight: 500;
    line-height: 36px;
    margin: 0px;
}

.contentWrap h2 {
    font-weight: 700;
    font-size: 45px;
}

.heroSection h2 {
    line-height: 62px;
}

.infoSection h2 {
    line-height: 40px;
}

.contentWrap h1,
.contentWrap h2 {
    margin: 0px;
    padding-bottom: 10px;
}

.infoSection {
    background-color: #F7F7F7;
    color: #1e2140;
}

.infoSection .headingWrap {
    padding-top: 27px;
    padding-bottom: 15px;
}

.infoSection .textWrap {
    padding: 15px 0px;
}

.infoSection p {
    font-size: 20px;
}

.infoSection p.paddingBottom {
    padding-bottom: 20px;
}

.formSection .contentWrap {
    padding: 81px 0px;
}

.tabs {
    border: 1px solid #d9d9d9;
}

.tabList {
    list-style-type: none;
    background-color: #f4f4f4;
    margin: 0px;
    padding: 0px;
    border-bottom: 1px solid #d9d9d9;
}

.tab {
    display: table-cell;
    box-sizing: border-box;
    cursor: pointer;
    padding: 4px 30px;
    line-height: 1.7em;
    font-size: 14px;
    font-weight: 500;
    color: #666;
    vertical-align: middle;
}

.tab:focus {
    outline: none;
}

.registerSelected {
    background-color: white;
    border-right: 1px solid #d9d9d9;
}

.loginSelected {
    background-color: white;
    border-left: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
}

.loginForm {
    padding-top: 30px;
    padding-bottom: 45px;
    padding-left: 54px;
    padding-right: 54px;
}

.inputWrap {
    margin-bottom: 30px;
    width: 450px;
    margin-left: auto;
    margin-right: auto;
}

.inputField {
    padding-top: 15px;
}

.inputField .labelContainer {
    padding-bottom: 8px;
}

.inputField label {
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    color: #555;
}

.inputField input {
    display: block;
    width: 100%;
    font-size: 15px;
    font-weight: 400;
    height: 40px;
    padding: 0px 12px;
    border: 1px solid #ddd;
    border-radius: 2px;
}

.inputField input:focus {
    outline: none;
    border: 1px solid #bbb;
}

.loginCheckbox {
    margin-top: 30px;
    margin-bottom: 10px;
}

.loginCheckboxLabel {
    margin-left: 14px;
    color: #888; 
}

.loginButton,
.logoutButton {
    display: block;
    background-color: #33d1ad;
    width: auto;
    min-width: 250px;
    height: auto;
    margin: auto;
    font-size: 15px;
    font-weight: 400;
    line-height: 15px;
    color: white;
    padding: 16px 20px;
    border: none;
    border-radius: 4px;
    margin-bottom: 5px;
    cursor: pointer;
}

.pwForgotWrap {
    text-align: center;
    padding-top: 20px;
}

.forgotPassword {
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    color: #888;
}

.forgotPassword:hover {
    text-decoration: underline;
}

.formSection h2 {
    font-size: 26px;
    font-weight: 500;
    line-height: 1em;
    color: #333;
}

.registerWrap {
    padding: 24px 30px;
}

.registerWrap fieldset {
    border: 0px;
    padding: 0px;
    margin: 0px;
}

.registerWrap legend {
    margin-bottom: 8px;
    color: #666;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.4em;
    padding: 0px;
}

.registerWrap label {
    display: block;
    padding-top: 5px;
    color: #666;
}

.fieldRequired {
    padding-left: 0.25em;
    color: #c02b0a;
    font-size: 13px;
    font-style: italic;
    font-weight: 400;
}

.complexInput {
    display: flex;
    justify-content: space-between;
    gap: 2%;
    margin-bottom: 2%;
}

.labelledInput {
    width: 100%;
}

.registerWrap input {
    width: 100%;
    font-size: 15px;
    font-weight: 400;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 2px;
    box-sizing: border-box;
    color: #4e4e4e;
}

.registerWrap input:focus {
    outline: none;
    border: 1px solid #bbb;
}

.companyInput {
    margin-bottom: 2%;
}

.marketingRadio input {
    width: auto;
    margin: 0px;
}

.marketingRadio label {
    display: inline-block;
    margin-left: 10px;
}

.registerWrap .marketingRadio {
    margin-bottom: 2%;
}

.registerWrap .buttonWrap {
    padding: 16px 0px;
    margin-top: 6px;
}

.registerWrap .buttonWrap button {
    box-sizing: border-box;
    background-color: #33d1ad;
    color: white;
    padding: 0px 20px;
    border: 10px solid #33d1ad;
    border-radius: 50px;
    font-size: 17px;
    font-weight: 700;
    line-height: 28.9px;
    cursor: pointer;
}

.filesWrap {
    border: 1px solid #BBB;
    max-height: 400px;
    overflow-y: scroll;
    padding-left: 15px;
    margin-bottom: 15px;
}

.filesWrap p {
    color: #2ea3f2;
    cursor: pointer;
}

.loaderWrap {
    border: 1px solid #BBB;
    height: 400px;
    margin-bottom: 15px;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #1e2140;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.errorBox {
    border: 1.5px solid #c02b0a;
    background-color: #fff9f9;
    margin-bottom: 15px;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 1px 4px rgba(0,0,0,.11),0 0 4px rgba(18,25,97,.0405344);
}

.errorBox p {
    margin: 0px;
    color: #c02b0a;
    font-weight: 500;
}

.errorBox li {
    color: #c02b0a;
}

.errorBox strong {
    font-weight: 500;
}

@media only screen and (max-width: 980px) {
    .contentWrap {
        width: 70%;
    }
}

@media only screen and (max-width: 768px) {
    .contentWrap {
        width: 90%;
    }
}