.buttonWrap {
    padding: 20px 0px;
}

.buttonWrap a {
    font-weight: 700;

    --s: 12px;
    /* the size on the corner */
    --t: 3px;
    /* the thickness of the border */
    --g: 20px;
    /* the gap between the border and image */

    padding: 5px calc(var(--g) - var(--t));
    border: var(--t) solid #0d73b2;
    /* the color here */
    mask:
        conic-gradient(at var(--s) var(--s), #0000 75%, #000 0) 0 0/calc(100% - var(--s)) calc(100% - var(--s)),
        linear-gradient(#000 0 0) content-box;
    cursor: pointer;
}

.buttonWrap a:hover {
    color: white !important;
    border-color: white !important;
}