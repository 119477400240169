.blogHero {
    background-image: url("../../../../public/images/backgrounds/Identeq-Background-Blue.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding: 159px 0px; /*hardcoded temporarily*/
    position: relative;
    z-index: -10;
}

.backgroundDivider {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMHZ3IiB2aWV3Qm94PSIwIDAgMTI4MCAxNDAiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iI2ZmZmZmZiI+PHBhdGggZD0iTTAgMTQwaDEyODBDNTczLjA4IDE0MCAwIDAgMCAweiIgZmlsbC1vcGFjaXR5PSIuMyIvPjxwYXRoIGQ9Ik0wIDE0MGgxMjgwQzU3My4wOCAxNDAgMCAzMCAwIDMweiIgZmlsbC1vcGFjaXR5PSIuNSIvPjxwYXRoIGQ9Ik0wIDE0MGgxMjgwQzU3My4wOCAxNDAgMCA2MCAwIDYweiIvPjwvZz48L3N2Zz4=);
    background-size: 100% 10vw;
    width: 100%;
    height: 10vw;
    z-index: 1;
    transform: scale(-1, 1);
    position: absolute;
    bottom: 0px;
    right: 0px;
    left: 0px;
}

.heroText {
    color: white;
    width: 62%;
    margin: 0px auto;
}

.heroText h1 {
    font-size: 70px;
    font-weight: 700;
    margin: 0px 0px 10px
}

.heroText p {
    font-size: 22px;
    font-weight: 500;
    margin: 0px;
    line-height: 36px;
}

@media only screen and (max-width: 980px) {
    .heroText h1 {
        font-size: 40px;
    }

    .heroText p {
        font-size: 20px;
    }
}