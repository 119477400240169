.contentWrap {
    width: 62%;
    margin: auto;
    padding: 54px 0px;
}

.contentWrap h2 {
    margin-top: 0px;
    margin-bottom: 27px;
    padding-bottom: 10px;
    color: #1e2140;
    font-size: 36px;
    font-weight: 600;
    line-height: 1em;
    text-align: center;
}

.formWrap {
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
}

.contentWrap p {
    font-size: 17px;
    font-weight: 400;
    line-height: 1.5em;
    color: #777
}

.message {
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 90px;
}

.formWrap input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    font-family: tenon;
    font-size: 15px;
    font-weight: 400;
    height: 40px;
    border: 1px solid #ddd;
    border-radius: 2px;
    margin-bottom: 15px;
    padding: 0px 16px;
}

.formWrap input:focus {
    outline: none;
    border: 1px solid #bbb;
}

.resetButton {
    display: block;
    background-color: #33d1ad;
    width: auto;
    min-width: 250px;
    height: auto;
    margin: auto;
    font-size: 16px;
    font-weight: 400;
    line-height: 15px;
    color: white;
    padding: 16px 20px;
    border: none;
    border-radius: 4px;
    margin-top: 15px;
    margin-bottom: 5px;
    cursor: pointer;
    font-family: tenon;
}