.privacy {
    width: 62%;
    margin: auto;
    padding: 70px 0px;
    color: #1e2140;
}

.privacy .headingWrap, .privacy .textWrap {
    padding: 27px 0px;
}

.privacy h1, .privacy h2 {
    margin: 0px;
    padding-bottom: 10px;
    font-weight: 700;
}

.privacy h1 {
    font-size: 70px;
    line-height: 1em;
}


.privacy h2 {
    font-size: 34px;
    line-height: 1.3em;
}

.privacy p, .privacy ul {
    font-size: 17px;
    margin: 0em;
    padding-bottom: 1em;
}

.privacy p {
    font-weight: 500;
    line-height: 1.8em;
}


.privacy ul, .privacy ul ul {
    font-weight: 400;
    line-height: 1.55em;
    list-style-type: disc;
}

.privacy ul ul {
    padding-left: 1em;
}

@media only screen and (max-width: 980px) {
    .privacy {
        width: 70%;
    }
}

@media only screen and (max-width: 768px) {
    .privacy {
        width: 90%;
    }
}