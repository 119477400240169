/* global styles */

.page {
    --highlight-color: var(--address-portal-blue);
}

.page html {
  background-color: white;
  color: var(--identeq-blue);
  font-family: tenon, sans-serif;
}

.page body {
  margin: 0;
  box-sizing: border-box;
  position: relative;
}

.page h1, .page h2, .page h3, .page h4, .page h5, .page h6 {
  margin: 0;
  padding-bottom: 10px;
}

.page h1 {
  font-size: 75px;
  line-height: 1.2em;
}

.page h2 {
  font-size: 48px;
  line-height: 1.2em;
}

.page h4 {
  font-size: 30px;
  line-height: 1.2em;
}

.page p {
  font-weight: 200;
  line-height: 1.4em;
}

.page p, .page a {
  font-size: 23px;
  margin: 0;
}

.page a {
  text-decoration: none;
  color: var(--identeq-green);
}

.page small, .page small a {
  font-size: 15px;
  font-weight: 300;
  line-height: 1.4em;
}

.page hr {
  border-top: 2px solid #aaa;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  width: 70%;
}

@media only screen and (max-width: 980px) {
  .page h1 {
    font-size: 70px;
  }

  .page h2 {
    font-size: 50px;
  }

  .page h4 {
    font-size: 25px;
  }

  .page li a {
    font-size: 22px;
  }
}

@media only screen and (max-width: 530px) {
  .page h1 {
    font-size: 50px;
  }

  .page h2 {
    font-size: 40px;
  }
}

/* layout styles */

.page .mobileOnly {
    display: none;
}

@media only screen and (max-width: 768px) {
    .page .desktopOnly {
        display: none;
    }

    .page .mobileOnly {
        display: block;
    }
}

/* page styles */

.page {
    position: relative;
}

.page a {
    color: var(--identeq-green);
    position: relative;
    z-index: 20;
}

.logoWrap {
    width: 414px;
    max-width: 100%;
    position: relative;
    z-index: 20;
}

.thumbTopContainer {
    width: 1617.55px;
    min-width: 1617.55px;
    height: 637.375px;
    left: 50%;
    bottom: 0;
    position: absolute;
    pointer-events: none;
    z-index: 10;
}

.thumbprintTop {
    background-image: url("../../../../public/images/bd-portal-landing-page/img/thumbprint_background_vector_blue.svg");
    background-position: left bottom;
    background-repeat: no-repeat;
    transform-origin: left bottom;
    transform: scale(2) translate(3%, 57%);
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 10;
    pointer-events: none;
}

.thumbBottomContainer {
    width: 1617.55px;
    min-width: 1617.55px;
    height: 637.375px;
    left: 50%;
    position: absolute;
    pointer-events: none;
}

.thumbprintBottom {
    background-image: url("../../../../public/images/bd-portal-landing-page/img/thumbprint_background_vector_grey.svg");
    background-position: left top;
    background-repeat: no-repeat;
    transform-origin: left top;
    transform: scale(2) translate(3%, -57.2%);
    width: 100%;
    height: 100%;
    z-index: 10;
    pointer-events: none;
}

/* .page .cardSection {
    padding-bottom: 90px;
} */

.cardSection h2 {
    margin-bottom: 30px;
}

.page .downloadSection {
    padding-bottom: 100px;
}

.splitSection {
    display: flex;
}

.splitSection h2 {
    margin-bottom: 86px;
}

.splitSection > * {
    width: 50%;
}

.splitSection .right img {
    object-fit: contain;
    width: 100%;
}

.page .splitSection h2 {
    max-width: 100%;
}

.page .logosSection {
    padding-bottom: 110px;
}

.page .logosSection h2 {
    max-width: 70%;
    margin-bottom: 60px;
}

@media only screen and (max-width: 1600px) {
    .splitSection h2 {
        font-size: 50px;
    }
}

@media only screen and (max-width: 1366px) {
    .splitSection {
        flex-direction: column-reverse;
    }

    .splitSection > * {
        width: 100%;
    }

    .splitSection .right {
        display: flex;
        justify-content: center;
    }

    .splitSection .right img {
        width: 70%;
    }

    .splitSection h2 {
        font-size: 60px;
    }
}


@media only screen and (max-width: 980px) {
    .page .cardSection {
        padding-bottom: 40px;
    }

    .thumbprintTop {
        transform: scale(1.8) translate(-2%, 47%);
    }

    .thumbprintBottom {
        transform: scale(1.8) translate(-2%, -63%);
    }

    .splitSection h2 {
        font-size: 50px;
        margin-bottom: 40px;
    }

    .page .logosSection h2 {
        max-width: none;
        margin-bottom: 60px;
    }

}

@media only screen and (max-width: 768px) {
    .splitSection .right img {
        width: 100%;
        margin-bottom: 30px;
    }

    .page .logosSection h2 {
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 530px) {
    .thumbprintTop {
        transform: scale(1.4) translate(-2%, 47%);
    }

    .thumbprintBottom {
        transform: scale(1.4) translate(-2%, -65.9%);
    }

    .splitSection h2 {
        font-size: 40px;
        margin-bottom: 30px;
    }

    .page .downloadSection {
        padding-bottom: 50px;
    }

    .page .logosSection h2 {
        margin-bottom: 10px;
    }
}