.logoNav {
    display: flex;
    justify-content: space-between;
    gap: 10%;
}

.left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.left > * {
    flex: 0;
}

.left .logoWrap {
    margin-bottom: 30px;
}

.logoWrap img {
    height: 80%;
}

.right {
    width: 55%;
    min-width: 451px;
    display: flex;
    justify-content: space-between;
}

.footerNav {
    margin-right: 10%;
}

.footerNav h4 {
    color: #33d1ad;
    margin-bottom: 10px;
}

.footerNav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.footerNav li {
    line-height: 2.4em;
}

.footerNav a {
    color: white;
}

.footerNav .icon {
    border: 2px solid #33d1ad;
    border-radius: 50%;
    padding: 3px;
    color: #33d1ad;
}

.footer .footerDivider {
    margin-top: 60px;
}

.bottom {
    display: flex;
    padding: 40px 0;
}

.bottom::before {
    content: "";
    width: auto;
    flex: 1;
}

.certLogos {
    display: flex;
    width: 55%;
    height: 65px;
    justify-content: space-between;
    gap: 20px;
}

.certLogos .imgWrap img {
    object-fit: contain;
    object-position: center;
    height: 100%;
}

.left a {
    color: #33d1ad;
}

@media only screen and (max-width: 1280px) {
    .logoWrap img {
        height: 80%;
    }

    .footerNav {
        margin-right: 3%;
    }
}