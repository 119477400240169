.buttonWrap {
    padding: 20px 0px;
}

.buttonWrap .buttonText {
    font-size: 40px;
    font-weight: 700;

    color: var(--highlight-color, --bd-portal-blue);

    --s: 20px;
    /* the size on the corner */
    --t: 5px;
    /* the thickness of the border */
    --g: 0px;
    /* the gap between the border and image */

    padding: 0px calc(var(--s) - var(--t));
    border: var(--t) solid var(--highlight-color, --bd-portal-blue);
    /* the color here */
    mask:
        conic-gradient(at var(--s) var(--s), #0000 75%, #000 0) 0 0/calc(100% - var(--s)) calc(100% - var(--s)),
        linear-gradient(#000 0 0) content-box;
    cursor: pointer;
}

.buttonWrap a:hover {
    color: var(--hover-color, white);
    border-color: var(--hover-color, white);
}

@media only screen and (max-width: 980px) {
    .buttonWrap a {
        font-size: 40px;
        --s: 15px;
        --t: 5px;
    }
}

@media only screen and (max-width: 530px) {
    .buttonWrap a {
        font-size: 30px;
        --s: 12px;
        --t: 4px;
    }
}