.cardRow {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 1%;
    padding: 30px 0;
}

@media only screen and (max-width: 768px) {
    .cardRow {
        flex-direction: column;
        gap: 20px;
    }
}