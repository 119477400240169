#Testimonials_clientTestimonials {
    width: 62%;
    margin: auto;
    padding: 70px 0px;
}

#Testimonials_header_wrap {
    padding: 27px 0px;
}

#Testimonials_header_wrap h2 {
    color: #1e2140;
    font-weight: 700;
    font-size: 50px;
    line-height: 1em;
    margin: 0px;
    padding-bottom: 10px;
}

#Testimonials_header_wrap a {
    color: #2ea3f2;
}

#Testimonials_header_wrap p {
    font-size: 23px;
    font-weight: 500;
    line-height: 36px;
    padding-bottom: 2em;
}

#Testimonials_clientTestimonials_content_wrap {
    display: flex;
    padding-bottom: 27px;
}

#Testimonials_clientTestimonials_vid {
    width: 58.8%;
    margin-right: 3%;
}

.video-container {
    position: relative;
    padding-top: 56.25%;
    height: 0;
}

#Testimonials_clientTestimonials_vid video,
#Testimonials_Wave_img_margin video {
    display:block;
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

#Testimonials_clientTestimonials_vid .react-player__preview,
#Testimonials_Wave_img_margin .react-player__preview {
    position: absolute;
    top: 0;
    left: 0;
}

#Testimonials_clientTestimonials_text_wrap {
    width: 38.2%;
}

#Testimonials_Wave,
#Testimonials_ThamesWater,
#Testimonials_UU {
    display: flex;
}

#Testimonials_Wave_text_wrap,
#Testimonials_ThamesWater_text_wrap {
    background-image: url('../../img/Identeq-Background-White.jpg');
    box-sizing: border-box;
    padding: 11em 6.1em;
}

#Testimonials_UU_text_wrap {
    background-image: url('../../img/Identeq-Background-White.jpg');
    box-sizing: border-box;
    padding: 9.8em 6.1em;
}

#Testimonials_clientTestimonials_text_wrap h2,
#Testimonials_Wave_text_wrap h2,
#Testimonials_ThamesWater_text_wrap h2,
#Testimonials_UU_text_wrap h2 {
    color: #1e2140;
    font-weight: 700;
    font-size: 33px;
    line-height: 1em;
    margin: 0px;
    padding-bottom: 10px;
}

#Testimonials_clientTestimonials_text_wrap p,
#Testimonials_Wave_text_wrap p,
#Testimonials_ThamesWater_text_wrap p,
#Testimonials_UU_text_wrap p {
    font-size: 14px;
    margin: 0px;
    line-height: 36px;
    font-weight: 500;
    padding-bottom: 14px;
}

#Testimonials_Wave_link_wrap,
#Testimonials_ThamesWater_link_wrap,
#Testimonials_UU_link_wrap {
    margin-top: 40px;
}

#Testimonials_Wave_img_wrap,
#Testimonials_ThamesWater_img_wrap {
    background-image: url('../../img/Identeq-Background-Blue.jpg');
}

#Testimonials_UU_img_wrap {
    background-image: url('../../img/Identeq-Background-Green.jpg');
}

#Testimonials_Wave_img_wrap,
#Testimonials_Wave_text_wrap,
#Testimonials_ThamesWater_img_wrap,
#Testimonials_ThamesWater_text_wrap,
#Testimonials_UU_img_wrap,
#Testimonials_UU_text_wrap {
    width: 50%;
}

#Testimonials_Wave_img_wrap,
#Testimonials_ThamesWater_img_wrap,
#Testimonials_UU_img_wrap {
    display: flex;
}

#Testimonials_ThamesWater_img_margin,
#Testimonials_ThamesWater_img,
#Testimonials_UU_img_margin,
#Testimonials_UU_img {
    display: block;
    margin: auto;
}

#Testimonials_Wave_img_wrap {
    justify-content: center;
    align-items: center;
}

#Testimonials_Wave_img_margin {
    margin: 0 20%;
    flex: auto;
    width: 100%;
}

#Testimonials_Wave_img {
    width: 100%;
    height: 100%;
}

#Testimonials_ThamesWater_img {
    width: 40%;
    height: auto;
}

#Testimonials_UU_img {
    width: 60%;
    height: auto;
}

.contact-wrap {
    padding-top: 90px;
    padding-bottom: 130px;
}

@media only screen and (max-width: 1280px) {
    #Testimonials_clientTestimonials {
        width: 98%;
    }
}

@media only screen and (max-width: 980px) {
    #Testimonials_clientTestimonials {
        width: 70%;
    }

    #Testimonials_header_wrap h2 {
        font-size: 30px;
    }

    #Testimonials_header_wrap p {
        font-size: 20px;
    }

    #Testimonials_clientTestimonials_content_wrap {
        display: block;
    }

    #Testimonials_clientTestimonials_content_wrap > * {
        width: 100%;
    }

    #Testimonials_clientTestimonials_vid {
        margin-bottom: 30px;
    }

    #Testimonials_Wave,
    #Testimonials_ThamesWater,
    #Testimonials_UU {
        display: block;
    }

    #Testimonials_Wave_img_wrap,
    #Testimonials_Wave_text_wrap,
    #Testimonials_ThamesWater_text_wrap,
    #Testimonials_UU_text_wrap {
        width: 100%;
    }


    #Testimonials_ThamesWater_img_wrap,
    #Testimonials_UU_img_wrap {
        display: none;
    }

    .testimonials_row {
        border-bottom: 5px solid #1e2140;
    }
}

@media only screen and (max-width: 768px) {
    #Testimonials_clientTestimonials {
        width: 90%;
    }

    #Testimonials_Wave_text_wrap,
    #Testimonials_ThamesWater_text_wrap,
    #Testimonials_UU_text_wrap {
        padding-top: 45px;
        padding-right: 5%;
        padding-bottom: 45px;
        padding-left: 5%;
    }
}
