.blogInfo {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: auto;
    padding: 54px 0px;
}

.imgContainer {
    width: 37vw;
    margin-top: -6vw;
    margin-right: 32px; /*Icons on right have padding of 16*/
    box-shadow: 0px 16px 96px -24px rgba(0,0,0,0.3);
}

.imgContainer img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    display: block;
}

.info {
    color: #33d1ad;
    font-size: 18px;
}

@keyframes fadeTop {
    0% {
        opacity: 0;
        transform: translateY(-10%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.icon {
    width: 20px;
    height: 20px;
    animation: fadeTop 1s cubic-bezier(.77,0,.175,1) 1;
}

.postMetadata {
    padding-left: 16px;
    padding-right: 140px;
    padding-bottom: 50px;
}

.postMetadata td {
    padding-bottom: 26px;
}

.iconPadding {
    padding-right: 27px;
}

.authors h4 {
    margin: 0px;
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #33d1ad;
}

.authorsList {
    padding: 0px;
    margin: 0px;
}

.authorsList li {
    list-style-type: none;
    color: #666;
    font-size: 14px;
    padding-bottom: 7px;
}

@media only screen and (max-width: 980px) {
    .blogInfo {
        display: block;
        padding-bottom: 0;
    }

    .imgContainer {
        width: 100%;
        margin-top: 0;
        margin-right: 0;
        margin-bottom: 5%;
    }

    .postMetadata {
        padding-bottom: 0;
    }
}