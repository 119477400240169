/* Global */

:root {
  --identeq-blue: #1e2140;
  --identeq-green: #33d1ad;
  --bd-portal-blue: #0d73b2;
  --address-portal-blue: #3cc4de;
  --prospect-portal-blue: #2487ed;
  --highlight-color: var(--identeq-green);
  --form-text-blue: #475466;
}

html, body {
  font-family: tenon, sans-serif !important;
}

p {
  line-height: 1.6rem;
}

a, a:hover {
  text-decoration: none;
}

.button_light {
  border-color: #33d1ad;
  background-color: #33d1ad;
}

.button_dark {
  border-color: #1e2140;
  background-color: #1e2140;
}

.button_light,
.button_dark {
  color: #FFF;
  cursor: pointer;
  border-radius: 50px;
  border-width: 0;
  font-size: 17px;
  font-weight: 700 !important;
  line-height: 1.7em !important;
  padding: 15px 30px;
}

.button_light::after,
.button_dark::after {
  content: "\f054";
  font-family:"Font Awesome 5 Pro";
  font-weight: 500;
  margin-left: -0.5rem;
  opacity: 0;
  transition: all 300ms ease 0ms;
}

.button_light:hover::after,
.button_dark:hover::after {
  margin-left: 0;
  padding-left: 5px;
  opacity: 1;
}

/* HOME PAGE */

#home_FightFraud p {
  line-height: 2.3rem;
  font-size: 22px;
}

.home_inner_wrap {
  padding-left: 6.1em;
  padding-right: 6.1em;
}

.home_inner_wrap p {
  font-size: 22px;
  line-height: 1.8em;
}

.home_inner_wrap li {
  font-size: 24px;
  color: #1e2140;
}

.home_button_wrap {
  margin-top: 30px;
}

#home_PMaaS .home_button_wrap {
  margin-top: 10px;
}

#home_DataSolutions {
  background-image: linear-gradient(180deg, rgba(43, 135, 218, 0.08) 1%, rgba(41, 196, 169, 0) 38%, rgba(41, 196, 169, 0.09) 100%), url(img/data_solutions.png) !important;
  background-position: 50%;
  background-size: cover;

  color: white;
  position: relative;
  padding-top: 120px;
  padding-bottom: 125px;
}

.hero-text {
  width: 62%;
  margin: auto;
  padding: 27px 0px;
}

.hero-text .heading-wrapper, 
.hero-text .subheading-wrapper {
  margin-bottom: 2.75%;
}

.hero-text h1,
.hero-text h2 {
  margin: 0px;
  padding-bottom: 10px;
}

#home_DataSolutions_button_wrap {
  margin-top: 4%;
}

#home_NewApproach {
  background-color: #f7f7f7 !important;
}

#home_NewApproach .content {
  width: 62%;
  margin: auto;
  padding: 120px 0px;
}

#home_NewApproach_boxes_wrap {
  display: flex;
  padding: 15px 0px;
}

#home_FightFraud {
  width: 62%;
  margin: auto;
  padding-top: 70px;
  padding-bottom: 120px;
}

#home_FightFraud div:first-child,
#home_FightFraud h2 {
  margin-bottom: 0;
}

.fraud_inner_wrap {
  display: inline-flex;
  line-height: 36px;
  width: 48%;
}

.fraud_inner_wrap p {
  margin: 0px;
  font-weight: 500;
}

#fraud_left {
  margin-right: 3%;
}

.middle_box {
  margin: 0% 3%;
}

.home_product_row {
  display: flex;
}

.home_product_text_wrap,
.home_product_img_wrap {
  box-sizing: border-box;
  width: 50%;
}

.bd-portal-img-wrap {
  display: flex;
}

.home_box {
  transition: transform 300ms ease 0ms;
  background: linear-gradient(to right, #33D1AD 6px, transparent 6px) 0 0, linear-gradient(to right, #33D1AD 6px, transparent 6px) 0 100%, linear-gradient(to left, #33D1AD 6px, transparent 6px) 100% 0, linear-gradient(to left, #33D1AD 6px, transparent 6px) 100% 100%, linear-gradient(to bottom, #33D1AD 6px, transparent 6px) 0 0, linear-gradient(to bottom, #33D1AD 6px, transparent 6px) 100% 0, linear-gradient(to top, #33D1AD 6px, transparent 6px) 0 100%, linear-gradient(to top, #33D1AD 6px, transparent 6px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-color: white;
  padding: 4%;
  padding-top: 2vw;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-bottom: 3.5vw;
  width: 25%;
}

.inner-wrap {
  width: 50%;
  margin: auto;
  object-fit: contain;
}

#cot_portal_text_wrap {
  background-image: url('img/Identeq-Background-Green.jpg');
  background-size: cover;
  padding-top: 11em;
  padding-bottom: 11em;
}

#home_CotPortal_img {
  background-image: url('img/cot-portal-mockup.png');
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

#home_BDPortal_img {
  background-image: url('img/bd-portal-blue.png');
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  border: 200px solid white;
}

#bd_portal_text_wrap {
  background-image: url('img/Identeq-Background-White.jpg');
  background-size: cover;
  padding-top: 9.8em;
  padding-bottom: 9.8em;
}

#home_PMaaS_text_wrap {
  background-image: url('img/Identeq-Background-Blue.jpg');
  background-size: cover;
  color: #FFF;
  padding-top: 12.1em;
  padding-bottom: 12.1em;
}

#home_PMaaS_img_wrap {
  background-image: url('img/pmaas-meeting.png');
  background-size: cover;
}

.home_box_header {
  margin: 0px;
  padding-bottom: 10px;
  font-size: 24px;
  font-weight: 700;
  line-height: 2.3rem;
}

.home_box_image_wrap {
  height: 100px;
  margin-bottom: 23px;
}

.home_box_text_wrap {
  display: block;
}

.home_box_description p {
  margin: 0px;
  font-size: 17px;
  font-weight: 500;
  line-height: 1.7rem;
}

#home_DataSolutions h1 {
  font-size: 75px;
  line-height: 80px;
}

#home_DataSolutions h2 {
  color: #FFF;
  font-size: 24px;
  font-weight: 500;
  line-height: 28.6px;
}

#home_FightFraud h2,
#home_NewApproach h2 {
  color: #1e2140;
  font-size: 50px;
  margin: 0px;
  padding-bottom: 10px;
  line-height: 50px;
}

#home_FightFraud .heading-wrapper {
  padding: 27px 0px;
}

#home_FightFraud .text-wrapper {
  padding-bottom: 27px;
}

#home_NewApproach .heading-wrapper {
  padding-bottom: 27px;
}

#home_CotPortal h2,
#home_BDportal h2 {
  color: #1e2140;
  font-size: 33px;
  margin: 0px;
  padding-bottom: 10px;
}

#home_PMaaS h2 {
  margin: 0px;
  padding-bottom: 10px;
  font-size: 33px;
  line-height: 1em;
}

#home_CotPortal p,
#home_BDportal p {
  color: #1e2140;
  font-weight: 500;
  margin: 0px;
  padding-top: 30px;
  padding-bottom: 20px;
}

#home_PMaaS p {
  margin: 0px;
  padding: 30px 0px;
}

#home_CotPortal ul {
  margin: 0px;
  padding-left: 22px;
  padding-bottom: 22px;
  line-height: 26px;
}

#home_CotPortal li {
  line-height: 44px;
}

.contact-section {
  padding-top: 90px;
  padding-bottom: 130px;
}

.animate__fadeTop {
  animation: fadeTop 1s cubic-bezier(.77, 0, .175, 1) 1;
}

@keyframes fadeTop {
    0% {
        opacity: 0;
        transform: translateY(-10%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media only screen and (max-width: 980px) {
  .hero-text,
  #home_FightFraud,
  #home_NewApproach .content,
  .home_inner_wrap {
    width: 70%;
    margin: auto;
  }

  #home_DataSolutions h1 {
    font-size: 54px;
    line-height: 1em;
  }

  #home_FightFraud h2,
  #home_NewApproach h2 {
    font-size: 33px;
  }
  
  .fraud_inner_wrap,
  #home_NewApproach_boxes_wrap {
    display: block;
    width: 100%;
  }
  
  #home_FightFraud p {
    font-size: 16px;
    line-height: 28px;
  }

  #home_FightFraud #fraud_left {
    margin-bottom: 30px;
  }

  .home_box {
    width: 100%;
    padding: 4vw;
    box-sizing: border-box;
  }

  .middle_box {
    margin: 4% 0;
  }

  .home_product_img_wrap {
    display: none;
  }

  .home_product_text_wrap {
    width: 100%;
    padding: 0;
  }

  .home_inner_wrap {
    padding: 0;
  }
}

@media only screen and (max-width: 768px) {
  .hero-text,
  #home_FightFraud,
  #home_NewApproach .content,
  .home_inner_wrap {
    width: 90%;
    margin: auto;
  }
}