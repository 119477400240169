.flexbox {
    display: flex;
    width: 100%;
}

.text {
    width: 42%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 500px;
    position: relative;
}

.text > * {
    flex: 0;
    z-index: 20;
}

.getInTouch > * {
    margin-bottom: 10px;
}

.icon {
    border: 2px solid #33d1ad;
    border-radius: 50%;
    padding: 3px;
    margin-right: 15px;
    vertical-align: middle;
}

.getInTouch p {
    display: inline-block;
    vertical-align: middle;
}

.text > p {
    width: 400px;
}

.smallBlurb {
    display: none;
}

.form {
    width: auto;
    flex: 1;
    position: relative;
    z-index: 20;
}

.thumbContainer {
    width: 1617.55px;
    min-width: 1617.55px;
    height: 637.375px;
    right: 58%;
    bottom: 0;
    position: absolute;
    pointer-events: none;
    z-index: 10;
}

.thumbprintBackground {
    background-image: url("../../../../../public/images/bd-portal-landing-page/img/thumbprint_background_vector_blue.svg");
    background-position: right bottom;
    background-repeat: no-repeat;
    transform-origin: right bottom;
    transform: translate(-63%, 127%) rotate(70deg) scale(2);
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 10;
    pointer-events: none;
}

@media only screen and (max-width: 1366px) {
    .flexbox {
        display: block;
    }

    .text {
        width: 100%;
        display: block;
        min-height: 0;
        margin-bottom: 50px;
    }

    .getInTouch {
        position: relative;
    }

    .text > p {
        position: relative;
    }

    .flexbox .text h2 {
        max-width: none;
        margin-bottom: 30px;
    }

    .smallBlurb {
        display: block;
        position: relative;
        z-index: 20;
    }

    .largeBlurb {
        display: none;
    }
}

@media only screen and (max-width: 980px) {
    .thumbprintBackground {
        transform: translate(-50%, 115%) rotate(70deg) scale(1.8);
    }
}

@media only screen and (max-width: 530px) {
    .thumbprintBackground {
        transform: translate(-35%, 95%) rotate(70deg) scale(1.4);
    }
}