.dark {
  background-color: #1e2140;
  color: white;
}

.section {
  padding-top: 100px;
  padding-bottom: 80px;
  overflow: hidden;
}

.section :global(.content-wrap) {
  min-height: 300px;
}

.section h2 {
  max-width: 66%;
  position: relative;
  z-index: 20;
}

.section hr {
  width: 100%;
}

.contentWrap {
  width: 75%;
  margin: auto;
  position: relative;
}

@media only screen and (max-width: 980px) {
  .section {
    padding-top: 75px;
    padding-bottom: 40px;
  }

  .section h2 {
    max-width: none;
  }
}