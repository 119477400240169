.container {
    background-color: #f7f7f7;
    color: #1e2140;
    padding-top: 49px;
}

.contentWrap {
    width: 62%;
    margin: auto;
}

.headerWrap {
    padding-bottom: 27px;
}

.headerWrap h2 {
    margin: 0%;
    padding-bottom: 10px;
    font-size: 50px;
    font-weight: 700;
    line-height: 60px;
}

.text p {
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    margin: 0px;
    padding-bottom: 20px;
}

.buttonWrap a {
    border-color: #1e2140;
    background-color: #1e2140;
    color: #FFF;
    cursor: pointer;
    border-radius: 50px;
    border-width: 0;
    font-size: 18px;
    font-weight: 700 !important;
    line-height: 1.7em !important;
    padding: 15px 30px;
}

.buttonWrap a::after {
    content: "\f054";
    font-family: "Font Awesome 5 Pro";
    font-weight: 500;
    margin-left: -0.5rem;
    opacity: 0;
    transition: all 300ms ease 0ms;
}

.buttonWrap a:hover::after {
    margin-left: 0;
    padding-left: 5px;
    opacity: 1;
}

.buttonWrap {
    padding-top: 10px;
    padding-bottom: 42px;
}

@media only screen and (max-width: 980px) {
    .contentWrap {
        width: 70%;
    }

    .headerWrap h2 {
        font-size: 40px;
    }
}

@media only screen and (max-width: 768px) {
    .contentWrap {
        width: 90%;
    }
}