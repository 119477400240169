.hero {
    z-index: 1;
}

.hero > div {
    padding-top: 60px;
    position: relative;
    min-height: 300px;
}

.hero :first-child {
    margin-top: 0;
}

.hero h1 {
    max-width: 80%;
    position: relative;
    z-index: 20;
}

.hero div > * {
    margin-top: 3.8vh;
}

@media only screen and (max-width: 980px) {
    .hero h1 {
        max-width: none;
    }

    .hero :global(.content-wrap) {
        padding-top: 80px;
    }
}

@media only screen and (max-width: 530px) {
    .hero :global(.content-wrap) {
        padding-top: 50px;
    }
}