.iconWrap {
    width: 6vw;
    height: auto;
    object-fit: contain;
    padding-bottom: 25px;
}

.iconWrap img {
    height: 100%;
    width: auto;
    max-width: none;
}