.postBody {
    width: 62%;
    margin: auto;
    padding: 54px 0px;
}

.postText {
    padding: 54px 0px 0px;
}

.reportContent {
    color: rgb(30, 33, 64);
}

.reportContent h1 {
    font-size: 45px;
    font-weight: 700;
    line-height: 40px;
}

.reportContent p {
    font-size: 20px;
    font-weight: 500;
    line-height: 36px;
}

.blogContent {
    color: rgb(102, 102, 102);
    width: 80%;
    margin: auto;
}

.blogContent h3 {
    color: rgb(51, 51, 51);
    font-size: 26px;
    font-weight: 500;
    line-height: 26px;
    margin: 0px;
    padding-bottom: 10px;
}

.blogContent p, .blogContent li {
    font-size: 20px;
    font-weight: 500;
    line-height: 23.8px;
}

.blogContent p {
    margin-bottom: 20px;
}

.blogContent ol {
    margin-bottom: 20px;
}

.blogContent a {
    color: #2ea3f2;
}

.downloadButton {
    background-color: #33d1ad;
    padding: 0px 17px;
    margin-bottom: 54px;
    border: 10px solid #33d1ad;
    border-radius: 50px;
    font-size: 17px;
    font-weight: 700;
    line-height: 1.7em;
    color: white;
    display: inline-block;
    transition-property: all;
}

.downloadButton, .downloadButton::after {
    transition: all 300ms ease 0ms;
}

.downloadButton::after {
    content: "\f0d7";
    font-family: "Font Awesome 5 Pro";
    margin-left: -0.5rem;
    opacity: 0;
}

.downloadButton:hover {
    padding-left: 10px;
}

.downloadButton:hover::after {
    margin-left: 0;
    padding-left: 10px;
    opacity: 1;
}

.postNavigation {
    width: 90%;
    margin: auto;
    padding-top: 54px;
}

.postNavigation a, .postNavigation a:visited {
    color: #33d1ad;
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 5px;
}

.navPrev {
    float: left;
}

.navNext {
    float: right;
}

@media only screen and (max-width: 980px) {
    .postBody {
        width: 70%;
    }
    
    .postNavigation {
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .postBody {
        width: 90%;
    }
}