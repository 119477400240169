.animate__fadeTop {
    animation: fadeTop 1s cubic-bezier(.77, 0, .175, 1) 1;
}

@keyframes fadeTop {
    0% {
        opacity: 0;
        transform: translateY(-10%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.background {
    background-color: #F7F7F7;
}

.boxesWrap {
    display: flex;
    justify-content: space-between;
    gap: 3%;
    padding: 27px 0px;
    width: 62%;
    margin: auto;
    color: #1e2140;
}

.box {
    background: linear-gradient(to right, #000 6px, transparent 6px) 0 0, linear-gradient(to right, #000 6px, transparent 6px) 0 100%, linear-gradient(to left, #000 6px, transparent 6px) 100% 0, linear-gradient(to left, #000 6px, transparent 6px) 100% 100%, linear-gradient(to bottom, #000 6px, transparent 6px) 0 0, linear-gradient(to bottom, #000 6px, transparent 6px) 100% 0, linear-gradient(to top, #000 6px, transparent 6px) 0 100%, linear-gradient(to top, #000 6px, transparent 6px) 100% 100%;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-color: white;
    box-sizing: border-box;

    transition: transform 300ms ease 0ms;
  
    display: inline;
    line-height: 27px;
    padding-left: 2vw;
    padding-top: 2.5vw;
    padding-right: 2vw;
    padding-bottom: 2.5vw;
    width: 22.75%;
}

.boxImgWrap {
    margin-bottom: 30px;
}

.boxImgWrap img {
    display: block;
}

.boxTextWrap p {
    font-size: 17px;
    font-weight: 500;
    line-height: 27px;
    margin: 0px;
}
