
/* Wraps */


#publications-background-wrap {
    background-image: url('../../img/Identeq-Background-White.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
}

#publications-background-wrap .publications-content-wrap {
    width: 62%;
    box-sizing: border-box;
    margin: auto;
    padding-top: 52px;
    padding-bottom: 90px;
}

#publications_box_wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    column-gap: 8%;
    row-gap: 100px;
    box-sizing: border-box;
}

#publications_takecontrol_background_wrap {
    padding: 54px 0px;
}

#publications_takecontrol_background_wrap .publications-content-wrap {
    width: 62%;
    margin: auto;
    padding: 15px 0px;
}

#publications_takecontrol_background_wrap {
    background-image: url('../../img/Identeq-Background-Blue.jpg');
    color: #fff;
}

#publications_takecontrol_background_wrap p {
    margin: 0px;
    padding-bottom: 20px;
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
}

#publications_takecontrol_btn_wrap {
    margin-top: 2.5%;
}

#publications_box_wrap h1 {
    font-size: 65px;
}

.publications_box {
    transition: transform 300ms ease 0ms;
    background: linear-gradient(to right, #33D1AD 6px, transparent 6px) 0 0, linear-gradient(to right, #33D1AD 6px, transparent 6px) 0 100%, linear-gradient(to left, #33D1AD 6px, transparent 6px) 100% 0, linear-gradient(to left, #33D1AD 6px, transparent 6px) 100% 100%, linear-gradient(to bottom, #33D1AD 6px, transparent 6px) 0 0, linear-gradient(to bottom, #33D1AD 6px, transparent 6px) 100% 0, linear-gradient(to top, #33D1AD 6px, transparent 6px) 0 100%, linear-gradient(to top, #33D1AD 6px, transparent 6px) 100% 100%;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-color: white;
  
    box-sizing: border-box;
    display: block;
    margin: 2% 0% 6% 0%;
    width: 100%;
    height: 100%;
}

.publications_box_img_wrap img {
    margin-bottom: 30px;
}

.publications_box_text_wrap {
    padding-right: 19px;
    padding-bottom: 19px;
    padding-left: 19px;
}

/* Text */

#publications_header_wrap {
    padding: 27px 0px;
}

#publications_header_wrap h1 {
    font-weight: 700;
    font-size: 70px;
    color: #1e2140 !important;
    line-height: 80px;
    margin: 0px;
    padding-bottom: 10px;
}

.publications_box h2 {
    font-size: 18px;
    font-weight: 400;
    line-height: 1em;
    margin: 0px;
    padding-bottom: 10px;
}

.publications_box p {
    margin: 0px;
    color: #666;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.7em;
}

.publications_box .post-meta {
    margin-bottom: 6px;
}

.publications_box h2 a {
    color: #333;
    text-decoration: none;
}

#publications_takecontrol_background_wrap h2 {
    font-weight: 700;
    font-size: 35px;
    line-height: 40px;
    margin: 0px;
    padding-bottom: 10px;
}

.takecontrol-header-wrap {
    margin-bottom: 15px;
}

.contact-wrap {
    padding-top: 90px;
    padding-bottom: 130px;
}

@media only screen and (max-width: 980px) {
    #publications-background-wrap .publications-content-wrap,
    #publications_takecontrol_background_wrap .publications-content-wrap {
        width: 70%;
    }

    #publications_header_wrap h1 {
        font-size: 50px;
    }

    #publications_box_wrap {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 768px) {
    #publications-background-wrap .publications-content-wrap,
    #publications_takecontrol_background_wrap .publications-content-wrap {
        width: 90%;
    }

    #publications_box_wrap {
        grid-template-columns: repeat(1, 1fr);
    }
}