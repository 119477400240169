.container {
    width: 30%;
    margin-right: 5.5%;
    margin-bottom: 30px;
    background-color: white;
    line-height: 1.3em;
    color: #1e2140;
    box-shadow: 0px 2px 50px 0px rgba(110,130,208,0.18);
}

.container img {
    margin-bottom: 30px;
    width: 100%;
}

.info {
    padding: 0px 19px 19px;
}

.info h4 {
    color: #1e2140;
    font-size: 18px;
    font-weight: 600;
    margin: 0px;
    padding-bottom: 10px;
}

.date {
    color: #31d1ad;
    font-size: 13px;
    font-weight: 600;
    margin: 0px 6px 0px 0px;
}

.blurb {
    font-size: 14;
    font-weight: 500;
    margin: 0px;
}

@media only screen and (max-width: 980px) {
    .container {
        width: 48%;
        margin-right: 0;
    }
}

@media only screen and (max-width: 768px) {
    .container {
        width: 100%;
        margin-bottom: 10%;
    }
}