.bdportal_row{
    /* padding: 5% 17.5%; */
    width: 100%;
    color: #1e2140;
}

.grey-background {
    width: 100%;
    background-color: #F7F7F7;
}

.bdportal-content-wrap {
    width: 62%;
    margin: auto;
    padding: 90px 0px;
}

#bdportal_bookDemo_imgtext_wrap {
    display: flex;
    padding: 27px 0px;
}

#bdportal_bookDemo_img_wrap,
#bdportal_bookDemo_text_wrap {
    display: inline-block;
    width: 46%;
}

#bdportal_bookDemo_img_wrap {
    margin-right: 4%;
}

#bdportal_bookDemo_text_wrap {
    margin-left: 4%;
}

#bdportal_bookDemo_text_wrap p {
    font-size: 23px;
    font-weight: 500;
    line-height: 36px;
    margin-top: 0;
    margin-bottom: 10%;
}

.bdportal_header_wrap {
    padding: 27px 0px;
}

#bdportal_tailoredContent .bdportal_header_wrap {
    padding: 15px 0px;
}

.bdportal_header_wrap h1 {
    margin: 0px;
    padding-bottom: 10px;
    font-size: 70px;
    font-weight: 700;
    line-height: 1em;
}

.bdportal_header_wrap h2 {
    font-weight: 700;
    font-size: 35px;
    line-height: 1em;
    padding-bottom: 10px;
    margin: 0px;
}

.bdportal_text_wrap{
    width: 70%;
}

.bdportal_text_wrap p {
    margin: 0px;
    font-size: 22px;
    font-weight: 500;
    line-height: 36px;
}

#bdportal_tailoredSolution {
    width: 62%;
    margin: auto;
    padding-top: 54px;
}

#bdportal_tailoredSolution .bdportal_text_wrap {
    padding-bottom: 27px;
}

#bdportal_tailoredSolution_box_wrap {
    padding: 27px 0px;
}

.bdportal_tailoredSolution_box p,
.bdportal_tailoredContent_box p{
    margin: 0px;
    font-size: 17px;
    font-weight: 500;
    line-height: 27px;
}

#bdportal_multipleDataSources,
#bdportal_tailoredContent {
    width: 62%;
    margin: auto;
}

#bdportal_tailoredContent {
    padding-bottom: 54px;
}

#bdportal_tailoredSolution_box_wrap,
.bdportal_tailoredContent_box_wrap {
    display: flex;
    justify-content: space-between;
    gap: 3%;
}

.bdportal_tailoredContent_box_wrap {
    padding: 27px 0px;
}

.bdportal_tailoredContent_box_wrap#bdportal-tc-last-row {
    padding-right: 25.75%;
    gap: 4%;
}

.bdportal_tailoredSolution_box,
.bdportal_tailoredContent_box {
    transition: transform 300ms ease 0ms;
    background: linear-gradient(to right, #000000 6px, transparent 6px) 0 0, linear-gradient(to right, #000000 6px, transparent 6px) 0 100%, linear-gradient(to left, #000000 6px, transparent 6px) 100% 0, linear-gradient(to left, #000000 6px, transparent 6px) 100% 100%, linear-gradient(to bottom, #000000 6px, transparent 6px) 0 0, linear-gradient(to bottom, #000000 6px, transparent 6px) 100% 0, linear-gradient(to top, #000000 6px, transparent 6px) 0 100%, linear-gradient(to top, #000000 6px, transparent 6px) 100% 100%;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-color: white;

    display: inline;
    padding-left: 2vw;
    padding-right: 2vw;
    /* margin-right: 3%; */
    width: 22.75%;
}

.bdportal_tailoredSolution_box {
    padding-top: 2.5vw;
    padding-bottom: 2.5vw;
}

.bdportal_tailoredContent_box {
    padding-top: 2vw;
    padding-bottom: 2vw;
}

.bdportal_tailoredContent_box h4 {
    margin: 0px;
    padding-bottom: 10px;
    font-size: 23px;
    font-weight: 700;
    line-height: 36px;
}

.bdportal_tailoredContent_box a {
    color: black;
    margin: -2vw;
    display: inline-block;
    z-index: 1;
    padding: 2vw;
}

.bdportal_tailoredContent_box_image_wrap {
    margin-bottom: 30px;
}

.bdportal_tailoredSolution_box_image_wrap {
    width: 90px;
    height: 90px;
    margin-bottom: 30px;
}

.bdportal_tailoredSolution_box_image_wrap img {
    width: 100%;
    height: auto;
    max-height: 100%;
    object-fit: contain;
    object-position: left;
    transform: scale(1.3, 1.3);
}

.bdportal_multipleDataSources_icons_wrap {
    display: flex;
    padding: 27px 0px;
    justify-content: space-between;
    gap: 3%;
}

.bdportal_multipleDataSources_icons_wrap#bdportal-mds-icons-last-row {
    padding-top: 0px;
    padding-bottom: 27px;
}

.bdportal_multipleDataSources_icon_wrap {
    width: 100%;
    /* margin: 1%; */
}

.bdportal_multipleDataSources_icon_wrap img {
    display: block;
    width: 100%;
}

.contact-wrap {
    padding: 90px 0px;
}

.animate__fadeTop {
  animation: fadeTop 1s cubic-bezier(.77, 0, .175, 1) 1;
}

@keyframes fadeTop {
    0% {
        opacity: 0;
        transform: translateY(-10%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media only screen and (max-width: 980px) {
    .bdportal-content-wrap,
    #bdportal_tailoredSolution,
    #bdportal_multipleDataSources,
    #bdportal_tailoredContent {
        width: 70%;
        margin: auto;
    }

    .bdportal_header_wrap h1 {
        font-size: 50px;
    }

    .bdportal_text_wrap {
        width: 100%;
    }

    #bdportal_tailoredContent .bdportal_text_wrap {
        margin-bottom: 30px;
    }

    #bdportal_bookDemo_imgtext_wrap {
        display: block;
    }

    #bdportal_bookDemo_img_wrap {
        display: block;
        margin-right: 0;
        margin-bottom: 30px;
        width: 100%;
    }

    #bdportal_bookDemo_text_wrap {
        display: block;
        margin-left: 0;
        width: 100%;
    }

    #bdportal_tailoredSolution_box_wrap,
    .bdportal_tailoredSolution_box,
    .bdportal_tailoredContent_box_wrap,
    .bdportal_tailoredContent_box {
        display: block;
        width: 100%;
    }

    .bdportal_tailoredContent_box_wrap {
        padding: 0;
    }

    .bdportal_tailoredSolution_box,
    .bdportal_tailoredContent_box {
        margin-bottom: 30px;
        padding: 4vw;
        box-sizing: border-box;
    }

    .bdportal_multipleDataSources_icons_wrap {
        flex-wrap: wrap;
    }

    .bdportal_multipleDataSources_icons_wrap {
        padding-bottom: 0;
    }

    .bdportal_multipleDataSources_icon_wrap {
        width: 40%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
    }

    .bdportal_tailoredContent_box_wrap#bdportal-tc-last-row {
        padding-right: 0;
    }
}

@media only screen and (max-width: 768px) {
    .bdportal-content-wrap,
    #bdportal_tailoredSolution,
    #bdportal_multipleDataSources,
    #bdportal_tailoredContent {
        width: 90%;
    }
}