.container {
    background-image: url('../../../img/Identeq-Background-Blue.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    color: #FFF;
    padding: 54px 0px;
}

.contentWrap {
    width: 62%;
    margin: auto;
    padding: 15px 0px;
}

.headerWrap {
    margin-bottom: 15px;
}

.headerWrap h2 {
    margin: 0px;
    padding-bottom: 10px;
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
}

.textWrap {
    margin-bottom: 2.75%;
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
}

.buttonWrap a {
    border-color: #1e2140;
    background-color: #1e2140;
    color: #FFF;
    cursor: pointer;
    border-radius: 50px;
    border-width: 0;
    font-size: 18px;
    font-weight: 700 !important;
    line-height: 1.7em !important;
    padding: 15px 30px;
}

.buttonWrap a::after {
    content: "\f054";
    font-family: "Font Awesome 5 Pro";
    font-weight: 500;
    margin-left: -0.5rem;
    opacity: 0;
    transition: all 300ms ease 0ms;
}

.buttonWrap a:hover::after {
    margin-left: 0;
    padding-left: 5px;
    opacity: 1;
}

@media only screen and (max-width: 980px) {
    .contentWrap {
        width: 70%;
    }
}

@media only screen and (max-width: 768px) {
    .contentWrap {
        width: 90%;
    }
}