.headerSection {
    background-color: #33d1ad;
    padding-top: 110px;
    padding-bottom: 90px;
    color: #1e2140;
}

.contentWrap {
    width: 62%;
    margin: auto;
    text-align: center;
}

.headerWrap {
    padding: 27px 0px;
}

.headerWrap h1 {
    margin: 0px;
    padding-bottom: 10px;
    font-size: 110px;
    font-weight: 700px;
    line-height: 120px;
}

.textWrap {
    width: 68%;
    margin: auto;
    padding-bottom: 27px;
}

.textWrap p {
    font-size: 22px;
    font-weight: 500;
    line-height: 36px;
    margin: 0px;
    padding-bottom: 22px;
}

.button_dark {
  border-color: #1e2140;
  background-color: #1e2140;
  color: #FFF;
  cursor: pointer;
  border-radius: 50px;
  border-width: 0;
  font-size: 17px;
  font-weight: 700 !important;
  line-height: 1.7em !important;
  padding: 15px 30px;
}

.button_dark::after {
  content: "\f054";
  font-family:"Font Awesome 5 Pro";
  font-weight: 500;
  margin-left: -0.5rem;
  opacity: 0;
  transition: all 300ms ease 0ms;
}

.button_dark:hover::after {
  margin-left: 0;
  padding-left: 5px;
  opacity: 1;
}

.contactWrap {
    padding-top: 90px;
    padding-bottom: 130px;
}